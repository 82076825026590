import Web3 from "web3";
import {MetaMaskSDK} from "@metamask/sdk";
import toast from "react-hot-toast";

export const getIpfsUrl = (value) => {
    return value?.replace("ipfs://", "https://ipfs.io/ipfs/")
}
export const addIpfsUrl = (value) => {
    return `https://ipfs.io/ipfs/${value}`
}
export const getCid = (value) => {
    return value?.replace("ipfs://", "")
}

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const connectToMetamask = async (onchangeCallback) => {
    if (typeof window.ethereum === 'undefined') {
        toast.error("Please install MetaMask!");
        return false;
    }

    const ChainID = window.env.REACT_APP_CHAIN_ID //Goerli Polygon TestNet

    const options = {
        injectProvider: false,
        communicationLayerPreference: 'webrtc',
    };

    const MMSDK = new MetaMaskSDK(options);

    const ethereum = MMSDK.getProvider();

    if (ethereum?.networkVersion !== ChainID) {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: Web3.utils.toHex(ChainID)}]
            });
        } catch (err) {
            console.log(err)
        }
    }
    const accounts = await ethereum.request({method: 'eth_requestAccounts', params: []})
    onchangeCallback(accounts)
    ethereum.on('accountsChanged', onchangeCallback );
    return accounts.length > 0
}


const abi = [
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_name",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "_symbol",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "_owner",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "_collectionMetaData",
                "type": "string"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "approved",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "newItemId",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "tokenMetaData",
                "type": "string"
            }
        ],
        "name": "addNFT",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "contractURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "deadCollection",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"
            }
        ],
        "name": "deadNFT",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getApproved",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "newItemId",
                "type": "uint256"
            }
        ],
        "name": "getNFT",
        "outputs": [
            {
                "internalType": "string",
                "name": "medatada",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getNFTs",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "metaData",
                        "type": "string"
                    },
                    {
                        "internalType": "uint256",
                        "name": "Id",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct MyNFT.NFT[]",
                "name": "",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "recipient",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "tokenURI",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "newItemId",
                "type": "uint256"
            }
        ],
        "name": "mintNFT",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_collectionMetadata",
                "type": "string"
            }
        ],
        "name": "updateCollection",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];


export const mint = async (data) => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(abi, data?.contractAddress);

    return contract.methods.mintNFT(data.address, data.cid, Web3.utils.toBN(data.id).toString()).send({from: data.address})

}

export const createCollectionFunc = async (data) => {

    console.log("data.ipfsJson in func", data.ipfsJson)
    const web3 = new Web3(window.ethereum);
    const contractFactoryAddress = window.env.REACT_APP_CONTRACT_FACTORY_ADDRESS;

    const contract = new web3.eth.Contract(contractFactoryAbi, contractFactoryAddress);


    console.log("contract after creat", contract)

    return contract.methods.createCollection(data.name, data.symbol, data.ipfsJson).send({from: data.address})

}
export const createDesignerFunc = async (data) => {
    const web3 = new Web3(window.ethereum);
    const contractDesignerAddress = window.env.REACT_APP_CONTRACT_DESIGNER_ADDRESS;
    const contract = new web3.eth.Contract(contractDesignerAbi, contractDesignerAddress);
    return contract.methods.addData(data?.ipfsJson).send({from: data?.address})
}
export const getAllDesignersFunc = async () => {
    const web3 = new Web3(window.ethereum);
    const contractDesignerAddress = window.env.REACT_APP_CONTRACT_DESIGNER_ADDRESS;
    const contract = new web3.eth.Contract(contractDesignerAbi, contractDesignerAddress);
    console.log("contract after creat", contract)
    return contract.methods.getAllData().call()
}
export const getDesignerFunc = async (data) => {
    const web3 = new Web3(window.ethereum);
    const contractDesignerAddress = window.env.REACT_APP_CONTRACT_DESIGNER_ADDRESS;
    const contract = new web3.eth.Contract(contractDesignerAbi, contractDesignerAddress);
    console.log("contract after creat", contract)
    return contract.methods.getData(data?.id).call()
}
export const updateDesignerFunc = async (data) => {
    const web3 = new Web3(window.ethereum);
    const contractDesignerAddress = window.env.REACT_APP_CONTRACT_DESIGNER_ADDRESS;
    const contract = new web3.eth.Contract(contractDesignerAbi, contractDesignerAddress);
    return contract.methods.updateData(data?.id, data?.ipfsJson).send({from: data?.address})
}


const contractDesignerAbi = [
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_data",
                "type": "string"
            }
        ],
        "name": "addData",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "_from",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "_id",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "string",
                "name": "_value",
                "type": "string"
            }
        ],
        "name": "NewItem",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_id",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "_data",
                "type": "string"
            }
        ],
        "name": "updateData",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "_from",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "_id",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "string",
                "name": "_value",
                "type": "string"
            }
        ],
        "name": "UpdateItem",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "getAllData",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "internalType": "string",
                        "name": "data",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "creator",
                        "type": "address"
                    }
                ],
                "internalType": "struct Storage.User[]",
                "name": "",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_id",
                "type": "uint256"
            }
        ],
        "name": "getData",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

const contractFactoryAbi = [
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_name",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "_symbol",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "_collectionMetadata",
                "type": "string"
            }
        ],
        "name": "createCollection",
        "outputs": [
            {
                "internalType": "contract MyNFT",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "getCollections",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "Id",
                        "type": "uint256"
                    },
                    {
                        "internalType": "contract MyNFT",
                        "name": "collectionAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "string",
                        "name": "collectionMetadata",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "collectionCreator",
                        "type": "address"
                    }
                ],
                "internalType": "struct CollectionFactory.Collection[]",
                "name": "",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]


export const onlyActive = (value, accounts, islogin)=> {
    if (!accounts) return false
    const validAccounts = accounts?.filter(account=> account.is_active )
        .map((account)=>account?.address)

    if (!islogin) return validAccounts.includes(value?.creator.toLowerCase())

    return (validAccounts.includes(value?.creator.toLowerCase()) && value?.hi)
}



