import React from 'react';
import classes from './Layout.module.css'
import {Outlet} from "react-router-dom";
import ScrollBar from "../../../components/ScrollBar";
import Header from "./Header/Header";

const Layout = () => {

    return (
        <div className={`${classes.container} column`}>

            <Header/>

            {/*<div className={`width-100 bg-red`}>This is a testnet environment </div>*/}
           <div className={`${classes.content} column`}>
               <ScrollBar>
                   <Outlet/>
                   <div className={`${classes.footer} flex jc-center ai-center`}>
                       <span className={`cursor-pointer hover-text`} onClick={()=>window.open("https://nilin.co")}>Designed and developed by Nilin</span>
                   </div>
               </ScrollBar>
           </div>



        </div>
    )
}

export default Layout