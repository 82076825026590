import React, {useEffect, useState} from 'react';
import classes from './DesignersCard.module.css'
import axios from "axios";
import {getCid, getIpfsUrl} from "../../../../../../utils";
import moment from "moment-jalaali";
import * as RoutesName from "../../../../../../Routes/routes";
import {Link, useNavigate} from "react-router-dom";
import Icon from "../../../../../../components/Icon/Icon";
import useAuth from "../../../../../../Hooks/useAuth";

const DesignersCard = ({data, id, multi}) => {

   // console.log("ipfs", ipfs)
    const {auth} = useAuth();
    const navigate = useNavigate()

    //console.log("data designerData" , designerData)

    const fullname = `${data?.firstname}-${data?.lastname}`;

    const navigateHandler = () => {

        if (multi) return navigate(RoutesName.ShowDesigner(data?.network_id, fullname))
        return false;

    }
    //console.log("cid", cid)

    return (
        <div className={`${classes.container} row ai-start width-100 rounded-8 position-relative mt-15 mb-5 px-2 py-2`}>
            <div className={`${classes.avatar} ${data?.hide && classes.activeHide} position-absolute`}
                 style={{backgroundImage: `url("${data?.imageLink}")`}}
            >
            </div>
            {data?.hide && <div className={`${classes.badge} rounded-8 width-7 flex jc-center ai-center position-absolute`}>
                Hidden
            </div>}

            <div className={`width-20 height-100 column jc-start ai-center ${classes.title} pb-05 ${data?.hide && "gray-filter"}`}>

                <span className={`font-weight-bold fs-02 mb-025`}>{data?.firstname}  {data?.lastname}</span>
                {/*<span className={`text-gray mt-025`}>ID: {id}</span>*/}
                <span className={`text-gray mt-025`}>{data?.title}</span>

            </div>

            <div className={`width-80 column pr-1`}>
                <div className={`row jc-between ai-center`}>
                    <div className={`row ${data?.hide && "gray-filter"}`}>{data?.email}</div>
                    <div className={`row ${data?.hide && "gray-filter"}`}>From {data?.nationality}</div>
                    <div className={`row ${data?.hide && "gray-filter"}`}>Born in {moment(data?.birthdate).format("MMM YYYY")}</div>

                    <div className={`row`}>
                        {auth?.isLogin &&
                            <Icon
                                iconName="icon-pencil-1 fs-01"
                                iconClass={`cursor-pointer hover-text`}
                                onClick={() => navigate(RoutesName.UpdaeDesigner(data?.network_id, fullname))}
                            />}
                        { multi &&<Icon
                            iconName=" icon-right-open fs-02"
                            iconClass={`cursor-pointer hover-text mr-1`}
                            onClick={() => navigateHandler()}
                        />}
                    </div>
                </div>

                <span className={`${classes.divider} width-100 my-2`}></span>


                <div className={` ${data?.hide && "gray-filter"}`}>

                    {
                        multi ? `${data?.description.slice(0,585)}...` : data?.description
                    }
                </div>

            </div>


        </div>
    );
};

export default DesignersCard;
