import React, {useEffect, useState} from 'react';
import classes from './Assets.module.css'
import {useGetCollectionAssets} from "../../../../query";
import Loading from "../../../../components/Loading/Loading";
import AssetsCard from "./Module/AssetsCard/AssetsCard";
import Icon from "../../../../components/Icon/Icon";
import * as RoutesName from "../../../../Routes/routes";
import {useNavigate, useParams} from "react-router-dom";
import Title from "../../../../components/Title/Title";
import useAuth from "../../../../Hooks/useAuth";

const Assets = ({creator}) => {

    const navigate = useNavigate();
    const {auth} = useAuth();
    const {address, name} = useParams()
    const {} = useParams()

    const {data , isLoading , error} = useGetCollectionAssets(address)

    const assets = data?.assets && data?.assets

    const content = () => {
        if (error) return <div className={`width-100 flex jc-center ai-center my-10`}>
            <span className={`fs-04`}>Failed to get items!</span>
        </div>

        if (isLoading) return <div className={`width-100 column jc-center ai-center my-10`}>
            <Loading/>
            <span className={`mt-5`}>Loading...</span>
        </div>

        if (data.length === 0 ) return <div className={`width-100 column jc-center ai-center my-10`}>
            <span className={`mt-5`}>No Asset!</span>
        </div>

        return assets.map((asset , index) => <AssetsCard data={asset} index={index} key={asset?.token_id}/>)

    }

    return (
        <div className={`px-7 my-4`}>
            <div className={`row jc-between ai-center mb-3`}>
                <Title title="Assets"/>
                <span className={`fs-02`}>{assets?.length}</span>
            </div>
            <div className={`width-100 row jc-start wrap`}>
                {content()}
            </div>
        </div>
    );
};

export default Assets;
