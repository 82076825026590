import React, {useEffect, useState} from 'react';
import {Route, Routes} from "react-router-dom";
import Layout from "./Layout/Layout";
import * as RoutesName from "../../Routes/routes";
import Home from "./Pages/Home/Home";
import Designers from "./Pages/Designers/Designers";
import Collections from "./Pages/Collections/Collections";
import Collection from "./Pages/Collection/Collection";
import Asset from "./Pages/Asset/Asset";
import useAuth from "../../Hooks/useAuth";
import i18n from "i18next";
import {Toaster} from "react-hot-toast";
import jwtDecode from "jwt-decode";
import Loading from "../../components/Loading/Loading";
import "./Styles/Tablet.css"
import Designer from "./Pages/Designer/Designer";

const Tablet = () => {

    console.log("is tablet")

    const {auth, setAuth} = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //const impersonate = query.get("impersonate");
        //dispatch(loadConfig(impersonate))
        i18n.language !== "fa" ? document.body.classList.add('ltr') : document.body.classList.remove('ltr');
        i18n.on("languageChanged", (lng) => {
            lng !== "fa" ? document.body.classList.add('ltr') : document.body.classList.remove('ltr');
        });
    }, []);

    const Toast = () => <Toaster position="top-right" toastOptions={
        {
            className: "rtl",
            style: {
                padding: "0.3vh 0.8vw 0.3vh 0.8vw",
                color: "white",
                lineHeight: "3vh",
                borderRadius: "8px",
                background: "var(--mainContent)",
            },
            success: {
                style: {
                    background: "var(--darkGreen)",
                },
            },
            error: {
                style: {
                    background: "var(--dyellow)",
                    color: "#000"
                },
                iconTheme: {
                    primary: '#777777',
                    secondary: 'black',
                },
            },
            custom: {
                style: {
                    background: "var(--Orange)",
                },
            },
        }}
    />
    useEffect(() => {
        let newAuth = {...auth}
        const verifyToken = localStorage.getItem('token');
        if (verifyToken) {
            const jwt = jwtDecode(verifyToken)
            const currentTime = Date.now()
            if (currentTime > jwt.exp * 1000) {
                newAuth = {
                    ...newAuth,
                    token: null,
                    display_name: null,
                    isLogin: false,
                }
            } else {
                newAuth = {
                    ...newAuth,
                    token: verifyToken,
                    display_name: jwt.display_name,
                    isLogin: true,
                }
            }

            setAuth(newAuth)
        }
        setLoading(false)

    }, []);


    if (loading) return <Loading isFullPage={true}/>

    return (
        <>
            <Routes>
                <Route element={<Layout/>}>
                    <Route path={RoutesName.Home} element={<Home/>}/>
                    <Route path={RoutesName.Designers} element={<Designers/>}/>
                    <Route path={RoutesName.Collections} element={<Collections/>}/>
                    <Route path={RoutesName.Collections + ":address" + "/:name"} element={<Collection/>}/>
                    <Route path={RoutesName.Assets() + ":id"} element={<Asset/>}/>
                    <Route path={RoutesName.ShowDesigner()} element={<Designer/>}/>
                </Route>
            </Routes>
            <Toast/>
        </>
    );
};

export default Tablet;
