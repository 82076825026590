import React from 'react';
import classes from './Header.module.css'
import {images} from "../../../../assets/images/images";
import Icon from "../../../../components/Icon/Icon";

import * as RoutesName from "../../../../Routes/routes";
import {Link} from "react-router-dom";

const Header = ({setActive}) => {
    return (
        <div className={`${classes.header} row jc-between ai-center px-7`}>

            <Link to={RoutesName.Home} className={`row jc-center ai-center`}>
                {/*<img src={images.dorsaLogo} alt="" className={`${classes.logo} rounded-4 ml-2`}/>*/}
                <span className={`font-weight-bold fs-04`}>NlN NFT</span>
            </Link>

            <Icon
                iconName="icon-menu_vertical flex fs-05"
                iconClass={`cursor-pointer`}
                onClick={()=>setActive(prevstate => !prevstate)}
            />


        </div>
    );
};

export default Header;
