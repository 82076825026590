import {useQuery} from "@tanstack/react-query";
import axios from "../../api/axios";

export const useGetCollection = (address) => {
    return useQuery(
        ['collection', [address]],
        async () => {
            if (!address) return;
            const {data} = await axios.get(`/api/v1/collections/${address}`)
            return data?.data;
        });
}