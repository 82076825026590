import React, {useEffect} from 'react';
import classes from './Collection.module.css'
import {useGetCollection} from "../../../../query";
import Icon from "../../../../components/Icon/Icon";
import moment from "moment-jalaali";
import Button from "../../../../components/Button/Button";
import Assets from "../Assets/Assets";
import CollectionInfo from "./Module/CollectionInfo/CollectionInfo";
import {useParams} from "react-router-dom";
import Loading from "../../../../components/Loading/Loading";

const Collection = () => {

    const {name,address} = useParams()

    const exploreURL = window.env.REACT_APP_BLOCK_EXPLORER;
    const openSeaURL = window.env.REACT_APP_OPENSEA_URL;

    const {data: collection, isLoading, error} = useGetCollection(address)

    if (isLoading) return <div className={`width-100 column jc-center ai-center min-height-100`}>
        <Loading/>
        <span className={`mt-5`}>Loading...</span>
    </div>

    return (
        <div className={`${classes.container} min-height-100`}>
            <div className={`${classes.header} position-relative`}
                 style={{backgroundImage: `url("${collection?.banner_image_url?.replace("w=500", "w=1920")}")`}}
            >

                <div className={`${classes.avatar} position-absolute flex jc-center ai-center`}
                     style={{backgroundImage: `url("${collection?.image_url}")`}}
                >

                </div>

            </div>
            <div className={`px-5 row jc-end ai-center m-auto`}>
                <div className={`pl-5 row jc-center ai-center mt-2`}>
                    <Icon
                        iconName="icon-schedule-calendar-icon-1 fs-05"
                        iconClass={`cursor-pointer`}
                    />
                    <span className={`mr-025`}>Created {moment( collection?.created_date).format("MMM YYYY")}</span>
                </div>
            </div>
            <div className={`${classes.content} px-7 m-auto column text-center`}>
                <span className={`font-weight-bold fs-06`}>{collection?.name}</span>
                <span className={`fs-02 mt-2`}>{collection?.description}</span>
            </div>
            <CollectionInfo collection={collection?.opensea?.collection}/>
            <div className={`width-100 row jc-between ai-center px-7 mt-3 mb-5`}>
                {collection?.opensea &&<Button
                    type="button"
                    buttonClass={`${classes.thisButton} cursor-pointer`}
                    buttonTitle="OpenSea"
                    onClick={()=>window.open(`${openSeaURL}/collection/${collection?.opensea?.collection?.slug}`)}
                />}
                <Button
                    type="button"
                    buttonClass={`${classes.thisButton} cursor-pointer`}
                    buttonTitle="Network"
                    onClick={()=>window.open(`${exploreURL}/address/${address}`)}
                />
            </div>

            <Assets creator={collection?.creator}/>
        </div>
    );
};

export default Collection;
