import React from 'react';
import classes from './Collections.module.css'
import {useNavigate} from "react-router-dom";
import useAuth from "../../../../Hooks/useAuth";
import {useGetCollectionByOwnerAddress} from "../../../../query";
import * as RoutesName from "../../../../Routes/routes";
import Icon from "../../../../components/Icon/Icon";
import Title from "../../../../components/Title/Title";
import Loading from "../../../../components/Loading/Loading";
import CollectionsCard from "./Module/CollectionsCard/CollectionsCard";

const Collections = ({slice}) => {

    const navigate = useNavigate();
    const {auth} = useAuth();

    const {data: collections, isLoading, error} = useGetCollectionByOwnerAddress()

    const content = () => {

        if (error) return <div className={`width-100 flex jc-center ai-center my-10`}>
            <span className={`fs-04`}>Failed to get Collections!</span>
        </div>

        if (isLoading) return <div className={`width-100 column jc-center ai-center my-10`}>
            <Loading/>
            <span className={`mt-5`}>Loading...</span>
        </div>

        if (collections?.length === 0) return <div className={`width-100 flex jc-center ai-center my-10`}>
            <span className={`fs-04`}>No Collection!</span>
        </div>

        return collections?.map((collection, index) => {
            if (slice && index > slice) return
            return <CollectionsCard data={collection} index={index} key={index}/>
        })
    }

    return (
        <div className={`width-80 m-auto min-height-100`}>

            <div className={`row jc-between ai-center mb-3 mt-5`}>
                <Title title="Collections"/>
                {slice ? <div className={`row jc-end ai-center hover-text cursor-pointer`}
                              onClick={() => navigate(RoutesName.Collections)}>
                    <span className={`ml-025`}>View All Collections</span>
                    <Icon
                        iconName="icon-right-open fs-01"
                        iconClass={`cursor-pointer`}
                    />
                </div> : <span className={`fs-02`}>{collections?.length}</span>}
            </div>
            <div className={`width-100 row jc-start wrap mb-10`}>
                {content()}
            </div>
        </div>
    );
};

export default Collections;
