import React, {useState} from 'react';
import classes from './Layout.module.css'
import ScrollBar from "../../../components/ScrollBar";
import {Outlet} from "react-router-dom";
import Header from "./Header/Header";
import ActionSheet from "./ActionSheet/ActionSheet";

const Layout = () => {

    const [active, setActive] = useState(false);

    return (
        <>
            <div className={`${classes.container} column`}>
                <Header setActive={setActive}/>
                <div className={`${classes.content}`}>
                    <ScrollBar>
                        <Outlet/>
                        <div className={`${classes.footer} flex jc-center ai-center`}>
                            <span className={`cursor-pointer hover-text`} onClick={()=>window.open("https://nilin.co")}>Designed and developed by Nilin</span>
                        </div>
                    </ScrollBar>
                </div>
                <ActionSheet active={active} setActive={setActive}/>
            </div>
        </>
    );
};

export default Layout;
