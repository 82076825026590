import dorsaLogo from "./src/dorsa-logo.svg";
import image from "./src/image.svg";
import media from "./src/media.svg";
import openSea from "./src/opensea.svg";
import linearLoading from "./src/linearLoading.gif";
import squareLoading from "./src/squareLoading.gif";
import dorsaLoading from "./src/dorsa-loading.gif";
import banner from "./src/dors-collection-banner.png";
import metamask from "./src/metamask-icon.svg";
import polygonscan from "./src/polygonscan.svg";
import etherscan from "./src/etherscan.svg";
import dorsaBrandLogo from "./src/dorsaBrandLogo.svg";
import dorsaBrandLogoYellow from "./src/dorsaBrandLogoYellow.svg";
import minidal from "./src/minidal.svg";
import dorsaFullLogo from "./src/dorsaFullLogo.svg";

export const images = {
  dorsaLogo: dorsaLogo,
  image: image,
  media: media,
  openSea: openSea,
  linearLoading: linearLoading,
  squareLoading: squareLoading,
  dorsaLoading: dorsaLoading,
  banner: banner,
  metamask: metamask,
  polygonscan: polygonscan,
  etherscan: etherscan,
  dorsaBrandLogo: dorsaBrandLogo,
  dorsaBrandLogoYellow: dorsaBrandLogoYellow,
  miniDalLogo: minidal,
  dorsaFullLogo: dorsaFullLogo,
};
