const MATERIAL_TYPE =  [
   'SwarovskiCrystal',
   '999Feingold',
   '750erGold',
   'Leather',
   'Metal',
   'Wood',
   'Iron',
   'Silver',
   'Titanium',
   'YKKZipper',
   'Others',
]
export default MATERIAL_TYPE;
