import React from 'react';
import classes from './Home.module.css'
import {images} from "../../../../assets/images/images";
import Collections from "../Collections/Collections";

const Home = () => {

    return (
        <div className={`min-height-100`}>
            <div className={`${classes.mainTitle} column jc-center ai-center position-relative`}>
                <img src={images.miniDalLogo} alt="" className={`position-absolute ${classes.miniDalTop}`}/>
                <img src={images.miniDalLogo} alt="" className={`position-absolute ${classes.miniDalRight}`}/>
                <img src={images.miniDalLogo} alt="" className={`position-absolute ${classes.miniDalLeft}`}/>
                <span className={`fs-22 font-weight-bold width-70 text-center mt-3 mb-3 ${classes.title}`}>
                    Discover rare 3D model and Collect NFT
                </span>
                <img src={images.dorsaFullLogo} alt="" className={`${classes.FullLogo} mt-3`}/>
            </div>
            <Collections slice={2}/>
        </div>
    );
};

export default Home;
