const MATERIAL_TYPE_TR =  {
    "SwarovskiCrystal": "Swarovski Crystal",
    "999Feingold": "999 Feingold",
    "750erGold": "750er Gold",
    "Leather": "Leather",
    "Metal": "Metal",
    "Wood": "Wood",
    "Iron": "Iron",
    "Silver": "Silver",
    "Titanium": "Titanium",
    "YKKZipper": "YKK Zipper",
    "Others": "Others",
}
export default MATERIAL_TYPE_TR;
