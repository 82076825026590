import React from "react";
import {images} from "../../assets/images/images";
import classes from './Loading.module.css'

const Loading = ({isFullPage , type}) => {
    return (
        <div className="container column ai-center jc-center" style={{height: isFullPage ? "100vh" : "100%"}}>
            <img
                className={`${ isFullPage ? classes.imageFullPage : classes.image}`}
                src={type === "linear" ? images.linearLoading :  isFullPage ? images.dorsaLoading : images.squareLoading} alt="loading..."/>
            {/*{type !== "linear" && <span className="flashit mt-1">در حال دریافت اطلاعات...</span>}*/}
        </div>
    );
};

export default Loading;