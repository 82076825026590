import React from 'react';
import classes from './AccountCard.module.css'
import toast from "react-hot-toast";
import ToggleSwitch from "../../../../../../components/ToggleSwitch/ToggleSwitch";
import {deleteAddress, updateAccountStatus, updateAddressStatus} from "../../../../../../api/auth";
import useAuth from "../../../../../../Hooks/useAuth";
import {useGetContractAddresses, useGetOwners} from "../../../../../../query";

const AccountCard = ({data}) => {


    console.log("data" , data)

    const {auth} = useAuth();
    const {refetch} = useGetOwners()

    const copyToClipboard = (value, e) => {
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success("Copied");
    }

    const accountStatusHandler = async (status) => {
        const statusData = {
            is_active: status.toString(),
            /*creator: data?.creator*/
        }
        await updateAccountStatus(data?.id, statusData, auth?.token)
            .then(() => {
                toast.success("Accounts status changed successfully")
                refetch()
            }).catch(() => {
                toast.error("Error in change address status")
            })
    }

    const typeTitleHandler = (type) => {

        if (type === "CollectionOwner") return "Collection Owner"
        if (type === "DesignerOwner") return "Designer Owner"

    }

    return (
        <div className={`${classes.container} row jc-between ai-center my-2 rounded-8 px-2`}>
            <span className={`width-5 flex jc-center`}>{data?.id}</span>
            <span className={`width-5 ${classes.border} flex jc-start`}></span>
            <span className={`width-20 flex jc-start`}>{typeTitleHandler(data?.type)}</span>
            <span className={`width-45 flex jc-start cursor-copy hover-text`}
                  onClick={(e) => copyToClipboard(data?.address, e)}>{data?.address}</span>
            <span className={`width-10 flex jc-start`}>
                <ToggleSwitch onchange={(e) => accountStatusHandler(e.target.checked)} checked={data?.is_active}/>
            </span>
        </div>
    );
};

export default AccountCard;
