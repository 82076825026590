import React, {useEffect, useRef, useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useAuth from "../../../../../../Hooks/useAuth";
import toast from "react-hot-toast";
import {createItem, updateDesigners} from "../../../../../../api/auth";
import {updateDesignerFunc} from "../../../../../../utils";
import Icon from "../../../../../../components/Icon/Icon";
import {images} from "../../../../../../assets/images/images";
import classes from "../CreateDesigner/CreateDesigner.module.css";
import * as RoutesName from "../../../../../../Routes/routes";
import Button from "../../../../../../components/Button/Button";
import Title from "../../../../../../components/Title/Title";
import TextInput from "../../../../../../components/TextInput/TextInput";
import DatePicker, {DateObject} from "react-multi-date-picker";
import transition from "react-element-popper/animations/transition";
import InputIcon from "react-multi-date-picker/components/input_icon";
import {Countries} from "../../../../../../utils/countries";
import {Genders} from "../../../../../../utils/genders";
import UploadFile from "../../../UploadFile/UploadFile";
import Loading from "../../../../../../components/Loading/Loading";
import ToggleSwitch from "../../../../../../components/ToggleSwitch/ToggleSwitch";
import {useGetDesigner} from "../../../../../../query";

const UpdateDesigner = () => {

    const {auth} = useAuth();
    const {id} = useParams()
    const {t} = useTranslation();
    const navigate = useNavigate();
    const exploreURL = window.env.REACT_APP_BLOCK_EXPLORER;


    const {data, isLoading, error} = useGetDesigner(id)
    const [openUploadFile, setOpenUploadFile] = useState(false);

    const [upLoadFileData, setUploadFileData] = useState({
        type: "",
        name: "",
    });


    const [uploading, setUploading] = useState(false);
    const [step, setStep] = useState("");

    const [itemData, setItemData] = useState({
        firstname: {value: "", error: []},
        lastname: {value: "", error: []},
        email: {value: "", error: []},
        birthdate: {value: "", error: []},
        title: {value: "", error: []},
        nationality: {value: "", error: []},
        description: {value: "", error: []},
        imageLink: {value: "", error: []},
        gender: {value: "", error: []},
        hide: {value: false, error: []},
    });

    const createdDesignerId = useRef("")
    const transactionHash = useRef("")

    useEffect(() => {
        if (data) {
            setItemData({
                ...itemData,
                firstname: {value: data?.firstname, error: []},
                lastname: {value: data?.lastname, error: []},
                email: {value: data?.email, error: []},
                birthdate: {value: data?.birthdate, error: []},
                title: {value: data?.title, error: []},
                nationality: {value: data?.nationality, error: []},
                description: {value: data?.description, error: []},
                imageLink: {value: data?.imageLink, error: []},
                gender: {value: data?.gender, error: []},
                hide: {value: data?.hide, error: []},
            })
        }
    }, [data]);


    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="items.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...itemData,
            [e.target.dataset.name]: {...itemData[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setItemData(prevState)
    }

    const isFormValid = () => {
        let inputs = {...itemData}

        const hasError = Object.values(itemData).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            console.log(key)
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="items.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setItemData(inputs);
        return !isEmpty;
    }

    useEffect(() => {
        let newItemData = {...itemData}
        if (auth?.designer_imageIpfsLink) newItemData.imageLink = {value: auth?.designer_imageIpfsLink, error: []}
        setItemData(newItemData)

    }, [openUploadFile])

    const navigateToUploadPage = (type, name) => {
        setUploadFileData({
            type: type,
            name: name,
        })
        setOpenUploadFile(true)
    }

    const copyToClipboard = (value, e) => {
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success("Copied");
    }

    const backAfterCreate = async () => {
        navigate(RoutesName.Designers)
    }

    const submit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) return false;

        if (!auth?.isConnected) return toast.error("Connect your wallet!")
        setUploading(true)

        const uploadDesignerData = {
            firstname: itemData.firstname.value,
            lastname: itemData.lastname.value,
            email: itemData.email.value,
            title: itemData.title.value,
            nationality: itemData.nationality.value,
            description: itemData.description.value,
            imageLink: itemData.imageLink.value,
            gender: itemData.gender.value,
            birthdate: itemData.birthdate.value,
            hide: itemData.hide.value,
        }

        await createItem(uploadDesignerData, auth.token)
            .then(async (res) => {
                setStep("CREATED")
                const updateDesignerData = {
                    id: id,
                    ipfsJson: `ipfs://${res?.data?.data?.value?.cid}`,
                    address: auth?.account,
                }
                await updateDesignerFunc(updateDesignerData).then(async (res) => {

                    createdDesignerId.current = res?.events?.UpdateItem?.returnValues?._id
                    transactionHash.current = res?.transactionHash

                    await updateDesigners(auth.token).then(() => {
                        setStep("UPDATED")
                    }).catch((e) => {
                        console.log("error", e)
                    })
                }).catch((err) => {
                    toast.error("Failed to Update Designer, try again!");
                    setStep("")
                    setUploading(false)
                    console.log("err", err)
                })
            })
            .catch((err) => {
                toast.error("Failed to upload file, try again!");
                setStep("")
                setUploading(false)
                console.log("err", err)
            })
    }


    const countryHandler = (val) => {
        setItemData({...itemData, nationality: {value: val.value, error: []}})
    }
    const genderHandler = (val) => {
        setItemData({...itemData, gender: {value: val.value, error: []}})
    }

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            border: '0.18vh solid #e7e7e7',
            padding: '1vh 0.5vw',
            direction: 'ltr',
            zIndex: 2
        }),
        multiValue: (provided) => ({
            ...provided,
            padding: '0 0.5vw',
            margin: '0.5vh 0.25vw',
            direction: 'ltr',
            zIndex: 2
        }),
    }
    const wrapperContentHandler = () => {
        if (step === "UPDATED") return <div className={`column jc-center ai-center`}>
            <div className={`row text-green mb-1`}>
                <span className={`ml-05`}>Created</span>
                <Icon
                    iconName="icon-ok-3 fs-04 "
                    iconClass={`cursor-pointer mr-05`}
                />
            </div>
            <div className={`row text-green mb-1`}>
                <span className={`ml-05`}>Updated</span>
                <Icon
                    iconName="icon-ok-3 fs-04 "
                    iconClass={`cursor-pointer mr-05`}
                />
            </div>

            <div className={`width-100 row jc-center ai-center my-3`}>
                <div className={`width-33 column jc-center ai-center`}>
                    <img src={images.dorsaLogo} alt="boxes" className={`${classes.linkImage}`}/>
                    <span className={`my-2`}>Dorsa</span>
                    <Icon iconName="icon-popup-link-icon flex" iconClass={` cursor-pointer hover`}
                          title={`${itemData?.firstname?.value} ${itemData?.lastname?.value} in Dorsa`}
                          onClick={() => window.open(`${RoutesName.ShowDesigner(createdDesignerId.current, `${itemData?.firstname?.value}-${itemData?.lastname?.value}`)}`)}/>
                </div>
                <div className={`width-33 column  column jc-center ai-center ${classes.etherscan}`}>
                    <img src={images.etherscan} alt="etherscan" className={`${classes.linkImage}`}/>
                    <span className={`my-2`}>Etherscan</span>
                    <Icon iconName="icon-popup-link-icon flex" iconClass={` cursor-pointer hover`}
                          title={`${itemData?.firstname?.value} ${itemData?.lastname?.value} in Etherscan`}
                          onClick={() => window.open(`${exploreURL}/tx/${transactionHash.current}`)}/>
                </div>
            </div>

            <div className={`container row jc-center ai-center my-2`}>
                <Icon iconName="icon-copy-link-icon flex" iconClass={`ml-025 cursor-pointer hover`}
                      onClick={(e) => copyToClipboard(transactionHash.current, e)}
                      title={`Click to copy Transaction Hash`}/>
                <span className={`fs-0-8 mr-025`}>{transactionHash.current}</span>
            </div>

            <Button
                type="button"
                buttonClass={`${classes.backButton} cursor-pointer mt-2`}
                buttonTitle="Back"
                onClick={() => backAfterCreate()}
            />

        </div>

        if (step === "CREATED") return <div className={`column jc-center ai-center`}>
            <div className={`row text-green mb-1`}>
                <span className={`ml-05`}>Created</span>
                <Icon
                    iconName="icon-ok-3 fs-04 "
                    iconClass={`cursor-pointer mr-05`}
                    onClick={() => navigate(RoutesName.CreateItem)}
                />
            </div>
            <span className={`mt-1`}>Updating...</span>
        </div>

        if (step === "") return <div>Creating...</div>
    }
    const hideHandler = async (status) => {
        setItemData({...itemData, hide: {value: status, error: []}})
    }

    const setBirthDate = (val) => {
        setItemData({...itemData, birthdate: {value: new DateObject(val).format(), error: []}})
    }

    if (isLoading) return <Loading/>


    return (
        <>
            <form onSubmit={(e) => submit(e)} className={`width-80 m-auto py-5 min-height-100`}>
                <div className={`row jc-between`}>
                    <Title title="Update Designer"/>
                    <div className={`row jc-center ai-center`}>
                        <span className={`ml-05`}>hide</span>
                        <ToggleSwitch onchange={(e) => hideHandler(e.target.checked)} checked={itemData?.hide.value}/>
                    </div>
                </div>
                <div className={`width-100 mt-3 row jc-between wrap`}>
                    <TextInput
                        label={t('createDesigner.firstname')}
                        data-name="firstname"
                        data-type="input"
                        data-min={2}
                        type="text"
                        placeholder={t('createDesigner.phFirstName')}
                        inputClass={`width-30 my-1`}
                        value={itemData.firstname.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.firstname.error}
                    />
                    <TextInput
                        label={t('createDesigner.lastname')}
                        data-name="lastname"
                        data-type="input"
                        data-min={2}
                        type="text"
                        placeholder={t('createDesigner.phLastName')}
                        inputClass={`width-30 my-1`}
                        value={itemData.lastname.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.lastname.error}
                    />
                    <TextInput
                        label={t('createDesigner.email')}
                        data-name="email"
                        data-type="input"
                        data-min={2}
                        type="text"
                        placeholder={t('createDesigner.phEmail')}
                        inputClass={`width-30 my-1`}
                        value={itemData.email.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.email.error}
                    />
                    <TextInput
                        label={t('createDesigner.title')}
                        data-name="title"
                        data-type="input"
                        data-min={2}
                        type="text"
                        placeholder={t('createDesigner.phTitle')}
                        inputClass={`width-30 my-1`}
                        value={itemData.title.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.title.error}
                    />

                    <div className={`width-30 my-1`}>
                        <span className={`font-weight-bold fs-01`}>{t('createDesigner.birthdate')}</span>
                        <DatePicker
                            format="MM/DD/YYYY"
                            maxDate={new Date()}
                            value={itemData.birthdate.value}
                            onChange={setBirthDate}

                            animations={[transition()]}
                            render={<InputIcon/>}
                            containerStyle={{
                                width: "100%",
                                height: "5vh",
                                marginTop: "0.5vh"
                            }}
                        />
                        {itemData?.birthdate?.error && <div className={`column fs-0-9 text-blue mt-025`}>
                            {itemData?.birthdate?.error.map((alert, index) => <span key={index} className={``}>{alert}</span>)}
                        </div>}
                    </div>
                    <div className={`width-30 my-1`}/>
                    <TextInput
                        label={t('createDesigner.nationality')}
                        data-name="nationality"
                        data-type="input"
                        data-min={2}
                        select={true}
                        type="text"
                        placeholder={t('createDesigner.phNationality')}
                        inputClass={`width-30 my-1 ${classes.selectInput}`}
                        value={{value: itemData.nationality.value, label: itemData.nationality.value}}
                        onchange={countryHandler}
                        alerts={itemData.nationality.error}
                        options={Countries().map(w => ({value: w, label: w}))}
                        isMulti={false}
                        isRtl={true}
                        closeMenuOnSelect={true}
                        styles={customStyles}
                    />
                    <TextInput
                        label={t('createDesigner.gender')}
                        data-name="gender"
                        data-type="input"
                        data-min={2}
                        select={true}
                        type="text"
                        placeholder={t('createDesigner.phGender')}
                        inputClass={`width-30 my-1 ${classes.selectInput}`}
                        value={{value: itemData.gender.value, label: itemData.gender.value}}
                        onchange={genderHandler}
                        alerts={itemData.gender.error}
                        options={Genders().map(w => ({value: w, label: w}))}
                        isMulti={false}
                        isRtl={true}
                        closeMenuOnSelect={true}
                        styles={customStyles}
                    />
                    <div className={`width-30 my-1`}/>
                </div>

                <TextInput
                    label={t('createDesigner.description')}
                    data-name="description"
                    data-type="input"
                    data-min={5}
                    type="text"
                    textarea={true}
                    placeholder={t('createDesigner.phDescription')}
                    inputClass={`width-100 my-3 ${classes.inputClass}`}
                    value={itemData.description.value}
                    onchange={(e) => inputHandler(e)}
                    alerts={itemData.description.error}
                />

                <TextInput
                    label={t('createDesigner.imageLink')}
                    data-name="imageLink"
                    data-type="input"
                    data-min={10}
                    type="text"
                    placeholder={t('createDesigner.phImageLink')}
                    subLabel={t('createDesigner.imageLinkSublabel')}
                    inputClass={`width-48 my-1`}
                    value={itemData.imageLink.value}
                    onchange={(e) => inputHandler(e)}
                    alerts={itemData.imageLink.error}
                    sideContent={<div className={`row jc-center ai-center mr-025 cursor-pointer hover-text`}
                                      onClick={() => navigateToUploadPage("image", "designer_image")}>
                        <Icon
                            iconName="icon-plus-circle fs-02"
                            iconClass={`cursor-pointer ml-025`}
                        />
                        <span>Upload File</span>
                    </div>}
                />

                <div className={`width-100 mt-5`}>
                    <Button
                        type="submit"
                        buttonClass={`${classes.thisButton} cursor-pointer`}
                        buttonTitle={"Update"}
                    />
                </div>

            </form>
            {uploading && <div className={`${classes.wrapper} position-absolute column jc-center ai-center`}>
                <span
                    className={`font-weight-bold fs-20 text-white mb-3`}>{step === "UPDATED" ? `${itemData.firstname.value} ${itemData.lastname.value} with id #${createdDesignerId.current} updated successfully` :
                    "Update Designer ..."}</span>

                <div
                    className={`${classes.wrapperContent} mt-3 width-38 rounded-8 column jc-center ai-center py-3 px-2`}>
                    {step !== "UPDATED" &&
                        <img src={images.dorsaLoading} className={`${classes.loading} mb-2`} alt="loading..."/>}
                    <span className={`fs-01`}>{wrapperContentHandler()}</span>
                </div>

            </div>}
            {openUploadFile && <UploadFile type={upLoadFileData?.type} name={upLoadFileData?.name}
                                           setOpenUploadFile={setOpenUploadFile}/>}
        </>
    );
};

export default UpdateDesigner;
