import React, {useEffect, useState} from 'react';

import {ResponsivePie} from '@nivo/pie'

const MaterialChart = ({data}) => {

    const [material, setMaterial] = useState([]);

    const createMaterialData = () => {
        const materialData = []
        for (let i = 0; i < data.length; i++) {
            materialData.push({
                id: data[i].type,
                label: data[i].type,
                value: data[i].percent,
            })
        }
        setMaterial(materialData)
    }

    useEffect(() => {
        if (data) createMaterialData()
    }, []);

    if (!data) {
        return <div className={`width-100 flex jc-center ai-center my-10`}>
            <span className={`fs-04`}>Material data not available</span>
        </div>
    }

    return (
        <>

            <div style={{height: "50vh"}} className={`fs-01 mt-2`}>
                <ResponsivePie
                    colors={['#eeeeee', '#eeeeee']}
                    colorBy="index"
                    borderColor={{from: "color", modifiers: [["darker", 0.2]]}}
                    data={material}
                    margin={{top: 43, right: 80, bottom: 80, left: 80}}
                    innerRadius={0.50}
                    padAngle={3}
                    cornerRadius={4}
                    fill={[
                        {background: "#000"}
                    ]}
                    theme={{
                        "text": {
                            "fontSize": 30,
                            "fontFamily": 'Mulish',
                            "fill": "#333333",
                            "outlineWidth": 0,
                            "outlineColor": "transparent"
                        },

                        "legends": {
                            "title": {
                                "text": {
                                    "fontSize": 15,
                                    "fontFamily": 'Mulish',
                                    "fill": "#000",
                                    "outlineWidth": 0,
                                    "outlineColor": "transparent"
                                }
                            },
                            "text": {
                                "fontSize": 15,
                                "fill": "#000",
                                "fontFamily": 'Mulish',
                                "outlineWidth": 0,
                                "outlineColor": "transparent"
                            },
                            "ticks": {
                                "line": {},
                                "text": {
                                    "fontSize": 15,
                                    "fill": "#000",
                                    "fontFamily": 'Mulish',
                                    "outlineWidth": 0,
                                    "outlineColor": "transparent"
                                }
                            }
                        },
                        "labels": {
                            "title": {
                                "text": {
                                    "fontSize": 15,
                                    "fontFamily": 'Mulish',
                                    "fill": "#000",
                                    "outlineWidth": 2,
                                    "outlineColor": "#000",
                                }
                            },
                            "text": {
                                "fontSize": 15,
                                "fill": "#000",
                                "fontFamily": 'Mulish',
                                "outlineWidth": 2,
                                "outlineColor": "#000",
                            },
                            "ticks": {
                                "line": {},
                                "text": {
                                    "fontSize": 15,
                                    "fill": "#000",
                                    "fontFamily": 'Mulish',
                                    "outlineWidth": 20,
                                    "outlineColor": "#000",
                                }
                            }
                        },

                        "grid": {
                            "line": {
                                "stroke": "#dddddd",
                                "strokeWidth": 1,
                                "fontSize": 18,
                                "fontFamily": 'Mulish',
                            }
                        },

                    }

                    }

                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextOffset={13}
                    arcLinkLabelsStraightLength={24}
                    arcLinkLabelsDiagonalLength={27}
                    arcLinkLabelsOffset={12}
                    arcLinkLabelsThickness={6}
                    arcLinkLabelsTextColor="#000"
                    arcLinkLabelsColor={{from: "color"}}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{from: "color", modifiers: [["darker", 3]]}}
                    activeInnerRadiusOffset={15}

                />
            </div>

        </>
    );
};

export default MaterialChart;
