import React from 'react';
import classes from './UploadFile.module.css'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import UploadImage from "./Module/UploadImage/UploadImage";
import Upload3DModel from "./Module/Upload3DModel/Upload3DModel";

const UploadFile = ({type, name, setOpenUploadFile}) => {


    //const {type} = useParams();

    const navigate = useNavigate();





    const content = () => {

        if (type === "image") return <UploadImage name={name} setOpenUploadFile={setOpenUploadFile}/>
        if (type === "3d-model") return <Upload3DModel name={name} setOpenUploadFile={setOpenUploadFile}/>

    }



    return (
        <div className={`${classes.container} column jc-between ai-center py-10`}>
            <div className={`${classes.header} row jc-center ai-center text-white`}>
                <span className={`font-weight-bold fs-20`}>Upload Media <span className={`fs-01 mr-05`}> To IPFS</span></span>
            </div>
            {content()}
        </div>
    );
};

export default UploadFile;
