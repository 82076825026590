import {useQuery} from "@tanstack/react-query";
import axios from "../../api/axios";
import useAuth from "../../Hooks/useAuth";

export const useGetAssetByDesigner = (designer) => {


    const {auth} = useAuth();
    let header = {}
    if (auth?.isLogin) {
        header = {
            headers: {
                'Authorization': `Bearer ${auth?.token}`,
                'Accept': 'application/json',
            }
        }
    }

    return useQuery(
        ['designer-asset', designer],
        async () => {
            const {data} = await axios.get(`/api/v1/assets/designer/${designer}`, header)
            return data?.data;
        },
        {
            staleTime: 60 * 1000,
            refetchOnMount: false
        }
    )
}