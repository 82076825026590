import React from 'react';
import classes from './Designers.module.css';
import DesignersCard from "./Module/DesignersCard/DesignersCard";
import Title from "../../../../components/Title/Title";
import {useGetDesigners} from "../../../../query";
import {useNavigate} from "react-router-dom";
import useAuth from "../../../../Hooks/useAuth";
import Loading from "../../../../components/Loading/Loading";

const Designers = () => {

    const navigate = useNavigate();
    const {auth} = useAuth();

    const {data, isLoading, error} = useGetDesigners()



    const content = () => {
        if (error) return  <span className={`my-10`}>Failed to get designers</span>
        if (isLoading) return  <span className={`my-10`}><Loading/></span>
        if (data?.length === 0) return  <span className={`my-10`}>No Designer!</span>
        return data?.map((d , index )=> <DesignersCard data={d} /*id={list?.id}*/ key={d?.id} multi={true}/>)
    }

    return (
        <div className={`${classes.container} width-80 m-auto py-5 column jc-start ai-center min-height-100`}>


            <span className={`mb-5 width-100`}><Title title="Designers"/></span>
            {content()}
        </div>
    );
};

export default Designers;
