import React from 'react';
import classes from './CollectionsCard.module.css'
import {Link} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";

const CollectionsCard = ({data}) => {
    return (
        <Link to={RoutesName.Collections + data?.address +"/"+ data?.name}
              className={`${classes.container} width-100 rounded-8 cursor-pointer`}>
            <div className={`height-50 ${classes.image}  position-relative flex jc-center ai-center`}
                 style={{backgroundImage: `url("${data?.banner_image_url?.replace("w=500", "w=1920")}")`}}
            >
                <div className={`${classes.avatar} position-absolute flex jc-center ai-center`}
                     style={{backgroundImage: `url("${data?.image_url}")`}}
                >
                </div>
            </div>
            <div className={`height-50 pt-7 px-4 pb-3 width-100 column jc-center ai-center`}>
                <span className={`font-weight-bold fs-01 mb-1`}>{data?.name}</span>
                <div className={`row jc-between ai-center width-100`}>
                    <div className={`row`}>
                        <span className={`ml-025`}>Volume:</span>
                        <span
                            className={`mr-025`}>{data?.opensea?.collection?.stats?.total_volume ? data?.opensea?.collection.stats?.total_volume : "---"}</span>
                    </div>
                    <div className={`row`}>
                        <span className={`ml-025`}>Floor price:</span>

                        <span className={`mr-025`}>{data?.opensea?.collection?.stats?.floor_price ? <span>{data?.opensea?.collection.stats?.floor_price} <span
                            className={`fs-0-8`}>ETH</span> </span> : "---"}</span>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default CollectionsCard;
