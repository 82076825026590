import React from 'react';

const ModelViewer = ({alt , src}) => {
    return (
        <model-viewer
            alt={alt}
            src={src}
            ar
            //environment-image="shared-assets/environments/moon_1k.hdr"
            //poster="shared-assets/models/NeilArmstrong.webp"
            shadow-intensity="2"
            camera-controls={true}
            touch-action="pan-y"
            auto-rotate={true}
            auto-rotate-delay={1000}
            orbit-sensitivity={10}
            rotation-per-second={1}
            // camera-orbit="75deg"
        />
    );
};

export default ModelViewer;
