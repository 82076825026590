import React, {useEffect} from 'react';
import classes from './Asset.module.css'
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";
import Loading from "../../../../components/Loading/Loading";
import {getIpfsUrl} from "../../../../utils";
import web3 from "web3";
import Button from "../../../../components/Button/Button";
import Icon from "../../../../components/Icon/Icon";
import {images} from "../../../../assets/images/images";
import ModelSection from "./Module/ModelSection/ModelSection";
import Material from "./Module/Material/Material";
import toast from "react-hot-toast";
import {useGetCollectionAssetById} from "../../../../query/hooks/useGetCollectionAssetById";
import DesignerMiniCard from "./Module/DesignerMiniCard/DesignerMiniCard";
import * as RoutesName from "../../../../Routes/routes";

const Asset = () => {

    const {t} = useTranslation();
    const {id, contractAddress, name} = useParams()
    const navigate = useNavigate();

    const {data, isLoading, error, refetch} = useGetCollectionAssetById(contractAddress, contractAddress, id)

    const asset = data?.assets[0] && data?.assets[0]
    const detail = data?.network && data?.network
    const owner = (!(data?.owner?.error) && data?.owner !== null) && data?.owner[0]?.owner

    const copyToClipboard = (value, e) => {
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success("Copied");
    }

    if (isLoading) return <div className={`width-100 column jc-center ai-center min-height-100`}>
        <Loading/>
        <span className={`mt-5`}>Loading...</span>
    </div>
    if (error) return <div className={`min-height-100 width-80 m-auto pt-3 pb-5 text-center py-10 flex jc-center ai-center fs-01`}>
        Failed to get Asset!
    </div>

    return (
        <div className={`column px-7 py-3 min-height-100`}>
            <div className={`width-100 row jc-between ai-center`}>
                <div className={`${classes.imageBox} width-55 flex jc-center ai-center rounded-8 py-2 px-2 position-relative`}>
                    <img src={getIpfsUrl(asset?.image_original_url)} alt=""/>

                    <div className={`${classes.subImage} position-absolute`}>
                        Asset {id} from <span className={`cursor-pointer hover-text`} onClick={()=>navigate(RoutesName.Collections + contractAddress +"/"+ name)}>{asset?.collection?.name}</span>
                    </div>

                </div>
                <div className={`${classes.infoBox} width-38 column jc-between ai-start pt-1`}>
                    <span className={`font-weight-bold fs-03`}>{asset?.name}</span>
                    <span className={``}>{/*Current price: */} {asset?.seaport_sell_orders !==  null ? <span><span className={`fs-05 font-weight-bold`}>{web3.utils.fromWei(asset?.seaport_sell_orders[0]?.current_price)}</span><span className={`mr-025`}> ETH</span></span>   : "---"}</span>

                    <div className={`column`}>
                        <div className={`fs-01`}>Owned by

                            { owner ? <>
                                    <span className={`mr-1`}>{owner?.user?.username}</span>
                                </>
                                :
                                <span className={`mr-025`}>---</span>
                            }
                        </div>

                        { owner &&  <span className={`fs-0-9 cursor-pointer hover-text`} onClick={(e)=>copyToClipboard(owner?.address, e)}> ( {owner?.address.slice(0, 5)}... )</span>}

                    </div>
                    <Button
                        type="button"
                        buttonClass={`${classes.thisButton} cursor-pointer mt-1`}
                        buttonTitle={t('item.buyNow')}
                        onClick={()=>window.open(`${asset?.permalink}`)}
                    />
                </div>
            </div>
            {/*<div className={`width-100 mt-1`}>
                <span className={`text-gray fs-0-9`}>Birthday at {detail?.birthday}</span>
            </div>*/}

            <div className={`column jc-between ai-start width-100 mt-2 text-gray fs-0-9`}>
                <span className={``}>NFC Tag: {detail?.nfcTag ? detail?.nfcTag : "---"}</span>
                <span className={``}>Birthdate at {detail?.birthdate}</span>
                <span className={``}>Made in {detail?.country}</span>
            </div>

            <div className={`width-100 mb-3 mt-1 ${classes.description}`}>{asset?.description}</div>

            <div className={`column`}>
                <div className={`column jc-start ai-start`}>
                    <div className={`row jc-center ai-center  text-gray mb-1`}>
                        <Icon
                            iconName="icon-link"
                            iconClass={`ml-1`}
                            //onClick={()=>navigate(Routes.Collection)}
                        />
                        <span className={``}>Links</span>
                    </div>
                    <Link to={`${asset?.permalink}`} target="_blank" className={`row jc-start ai-center hover-text fs-0-9`}>
                        <img src={images.openSea} alt="" className={`${classes.icon} ml-1`}/>
                        <span className={`mr-025`}>OpenSea</span>
                    </Link>




                </div>
                <div className={`column jc-start ai-start mt-2`}>
                    <div className={`row jc-center ai-center  text-gray mb-1`}>
                        <Icon
                            iconName="icon-pencil"
                            iconClass={`ml-025`}
                            //onClick={()=>navigate(Routes.Collection)}
                        />
                        <span className={``}>Designer</span>
                    </div>
                    <DesignerMiniCard data={detail?.designer}/>
                   {/*<div className={`row wrap jc-between`}>
                       <Link to={``} target="_blank" className={`row jc-start ai-center hover-text fs-0-9 mt-1`}>
                           <div className={`${classes.avatar} ml-025`}>

                           </div>
                           <span className={`mr-025 ${classes.avatarTitle} rounded-8`}></span>
                       </Link>
                       <Link to={``} target="_blank" className={`row jc-start ai-center hover-text fs-0-9 mt-1`}>
                           <div className={`${classes.avatar} ml-025`}>

                           </div>
                           <span className={`mr-025 ${classes.avatarTitle} rounded-8`}></span>
                       </Link>
                       <Link to={``} target="_blank" className={`row jc-start ai-center hover-text fs-0-9 mt-1`}>
                           <div className={`${classes.avatar} ml-025`}>

                           </div>
                           <span className={`mr-025 ${classes.avatarTitle} rounded-8`}></span>
                       </Link>
                   </div>*/}

                    <ModelSection name={asset?.name} animation_url={asset?.animation_original_url}/>
                    <Material material={detail?.material}/>
                </div>
            </div>


        </div>
    );
};

export default Asset;
