import {useQuery} from "@tanstack/react-query";
import axios from "../../api/axios";


export const useGetCollectionAssetById = (address, contractAddress, token_id) => {
    return useQuery(
        ['asset', [address, contractAddress, token_id]],
        async () => {
            if (!address) return;
            const {data} = await axios.get(`/api/v1/assets?address=${address}&contract=${contractAddress}&token_id=${token_id}`)
            return data?.data;
        });
}