import React, {useEffect, useState} from 'react';
import * as RoutesName from "../../Routes/routes";
import {Route, Routes} from "react-router-dom";
import Login from "./Pages/Login/Login";
import i18n from "i18next";
import "./Styles/Browser.css"
import Home from "./Pages/Home/Home";
import CreateItem from "./Pages/CreateItem/CreateItem";
import RequireAuth from "../../components/RequireAuth/RequireAuth";
import jwtDecode from "jwt-decode";
import useAuth from "../../Hooks/useAuth";
import Loading from "../../components/Loading/Loading";
import Asset from "./Pages/Asset/Asset";
import Layout from "./Layout/Layout";
import Collections from "./Pages/Collections/Collections";
import Collection from "./Pages/Collection/Collection";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Accounts from "./Pages/Accounts/Accounts";
import Toast from "../../components/Toast/Toast";
import {getCurrentWalletAddress} from "../../web3/web3Utils";
import CreateCollection from "./Pages/Collections/Module/CreateCollection/CreateCollection";
import UploadFile from "./Pages/UploadFile/UploadFile";
import Designers from "./Pages/Designers/Designers";
import CreateAsset from "./Pages/Assets/Module/CreateAsset/CreateAsset";
import CreateDesigner from "./Pages/Designers/Module/CreateDesigner/CreateDesigner";
import Designer from "./Pages/Designer/Designer";
import UpdateDesigner from "./Pages/Designers/Module/UpdaeDesigner/UpdateDesigner";
import Material from "./Pages/Material/Material";



const Browser = () => {

    console.log("is browser")

    const {auth, setAuth} = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        i18n.language !== "fa" ? document.body.classList.add('ltr') : document.body.classList.remove('ltr');
        i18n.on("languageChanged", (lng) => {
            lng !== "fa" ? document.body.classList.add('ltr') : document.body.classList.remove('ltr');
        });
    }, []);


    useEffect(() => {
        let newAuth = {...auth}
        const verifyToken = localStorage.getItem('token');
        if (verifyToken) {
            const jwt = jwtDecode(verifyToken)
            const currentTime = Date.now()
            if (currentTime > jwt.exp * 1000) {
                newAuth = {
                    ...newAuth,
                    token: null,
                    display_name: null,
                    isLogin: false,
                }
            } else {
                newAuth = {
                    ...newAuth,
                    token: verifyToken,
                    display_name: jwt.display_name,
                    isLogin: true,
                }
            }

            setAuth(newAuth)
        }
        setLoading(false)

    }, []);


    if (loading) return <Loading isFullPage={true}/>


    return (
        <>
            <Routes>
                <Route element={<RequireAuth/>}>
                    <Route path={RoutesName.CreateItem + "/*"} element={<CreateItem/>}/>
                    <Route path={RoutesName.UploadFile + "/:type" + "/:name"} element={<UploadFile/>}/>
                </Route>
                <Route path={RoutesName.Login} element={<Login/>}/>
                <Route element={<Layout/>}>
                    <Route element={<RequireAuth/>}>
                        <Route path={RoutesName.Dashboard} element={<Dashboard/>}/>
                        <Route path={RoutesName.Accounts} element={<Accounts/>}/>
                        <Route path={RoutesName.CreatCollection} element={<CreateCollection/>}/>
                        <Route path={RoutesName.CreateAsset + ":address" + "/:name"} element={<CreateAsset/>}/>
                        <Route path={RoutesName.UpdaeDesigner()} element={<UpdateDesigner/>}/>
                        <Route path={RoutesName.Material} element={<Material/>}/>
                    </Route>
                    <Route path={RoutesName.Home} element={<Home/>}/>
                    <Route path={RoutesName.Designers} element={<Designers/>}/>


                    <Route path={RoutesName.ShowDesigner()} element={<Designer/>}/>
                    <Route path={RoutesName.CreateDesigner} element={<CreateDesigner/>}/>
                    <Route path={RoutesName.Collections} element={<Collections/>}/>
                    <Route path={RoutesName.Collections + ":address" + "/:name"} element={<Collection/>}/>
                    <Route path={RoutesName.Assets() + ":id"} element={<Asset/>}/>
                </Route>
            </Routes>
            <Toast/>
        </>
    );
};

export default Browser;
