import axios from "./axios";


export const login = (loginData) => {
    return axios.post(`/api/v1/login`,loginData, {
        headers: {
            'Authorization': ``,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    });
}

export const Logout = (token) => {
    return axios.get(`/api/v1/logout`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}
export const deleteAddress = (token, id) => {
    return axios.delete(`/api/v1/contract/address/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}
export const addAddress = (token, data) => {
    return axios.post(`/api/v1/contract/address`,data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}
export const updateAddressStatus = (token, data) => {
    return axios.put(`/api/v1/contract/status`,data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}
export const updateAccountStatus = (id, data, token) => {
    return axios.put(`/api/v1/owners/${id}`,data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}

export const createItem = (itemData, token) => {

    const str = JSON.stringify(itemData);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
        type: "text/plain;charset=utf-8"
    });
    let file = new File([blob], {type: "text/plain"});
    let data = new FormData();
    data.append('file', file);

    return axios.post(`/api/v1/ipfs/upload`,data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}

export const uploadMedia = (media, token) => {

    console.log("token", token)

    let file = new File([media], {type: media.type});
    let data = new FormData();
    data.append('file', file);

    return axios.post(`/api/v1/ipfs/upload`,data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}

export const getCollection = () => {
    return axios.get(`/api/v1/collection`);
}
export const update = () => {
    return axios.get(`https://api.opensea.io/api/v1/asset/0xf4b49d376f63bbb247fc3b2425275c4efc080cdc/?force_update=true`);
}


export const updateDB = (token) => {

    return axios.get(`/api/v1/update-db`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}
export const updateOwners = (token) => {

    return axios.get(`/api/v1/update-owners`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}
export const updateDesigners = (token) => {

    return axios.get(`/api/v1/update-designers`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}
export const updateCollectionsAfterMintAsset = (contract,token) => {

    return axios.get(`/api/v1/collections/update/${contract}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
    });
}