import React from 'react';
import classes from './AssetsCard.module.css'
import * as RoutesName from "../../../../../../Routes/routes";
import {Link} from "react-router-dom";
import {getIpfsUrl} from "../../../../../../utils";
import web3 from "web3";

const AssetsCard = ({data}) => {

    console.log("data", data)

    return (
        <Link
            to={RoutesName.Assets(data?.asset_contract?.address, data?.collection?.name) + data?.token_id}
            className={`${classes.container} width-23 rounded-8 cursor-pointer`}>
            <div className={`height-65 flex jc-center ai-center ${classes.image}`}>
                <img src={getIpfsUrl(data?.image_original_url)} alt=""/>
            </div>
            <div className={`height-35 px-1 py-1 column jc-between `}>
                <span className={`font-weight-bold `}> {data?.name}</span>
                <span>{data?.collection?.name}</span>
                <span>{data?.seaport_sell_orders !== null ? <span><span
                    className={`fs-02`}>{data?.seaport_sell_orders ? web3.utils.fromWei(data?.seaport_sell_orders[0]?.current_price) : "---"}</span><span
                    className={`mr-025`}> ETH</span></span> : "---"}</span>
            </div>
        </Link>
    );
};

export default AssetsCard;
