import React, {useState} from 'react';
import classes from './Material.module.css'
import Title from "../../../../../../components/Title/Title";
import MaterialChart from "../MaterialChart/MaterialChart";
import MaterialCard from "../MaterialCard/MaterialCard";
import Icon from "../../../../../../components/Icon/Icon";

const Material = ({material}) => {

    const [chart, setChart] = useState(true)

    return (
        <div className={`column width-48 ${classes.container}`}>
            <div className={`width-100 row jc-between ai-center`}>
                <span className={`mb-2`}><Title title="Material"/></span>
                <Icon iconName={`${chart ? 'icon-th-large-1' : 'icon-chart-pie-1'} fs-02 flex cursor-pointer hover-text`}
                      customClass={`ml-05`}
                      onClick={() => setChart(prevState => !prevState)}/>
            </div>
            {chart ? <MaterialChart data={material}/> : <MaterialCard data={material}/>}
        </div>
    );
};

export default Material;
