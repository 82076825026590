import React, {useState} from 'react';
import classes from './Login.module.css'
import {images} from "../../../../assets/images/images";
import TextInput from "../../../../components/TextInput/TextInput";
import {useTranslation} from "react-i18next";
import Button from "../../../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../Routes/routes";
import useAuth from "../../../../Hooks/useAuth";
import {login} from "../../../../api/auth";
import Loading from "../../../../components/Loading/Loading";
import toast from "react-hot-toast";

const Login = () => {
    const {t} = useTranslation();
    let navigate = useNavigate();


    const {setAuth} = useAuth();



    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState([]);

    const [credential, setCredential] = useState({username: "", password: ""});



    const submit = async (e) => {
        e.preventDefault();

        if (credential.username.length === 0 || credential.password.length === 0) {
            return setLoginError([t("loginComp.emptyCredentialError")]);
        }

        if (credential.password.length < 4) {
            return setLoginError(t("loginComp.inputError"));
        }
        setLoginError(false);
        setLoading(true);
        await login(credential)
            .then(async (res) => {

                console.log("res" , res)
                setAuth({
                    isLogin: true,
                    token: res?.data?.data?.access_token,
                    display_name: res?.data?.data?.display_name,
                })
                return navigate(RoutesName.Dashboard);
            })
            .catch((err) => {
                setLoginError(t("loginErrorMsg." + err.response.data.error));
            })
            .finally(() => {
                setLoading(false);
            });
    }


    return (
        <div className={`${classes.container} column jc-center ai-center py-5`}>



            <form onSubmit={(e) => submit(e)} className={`${classes.content} rounded-8 column jc-between ai-center px-2 py-3 ${loading && 'filter-blur-4'} position-relative`}>

                <div className={`${classes.header} column jc-center ai-center`}>
                    <img src={images.dorsaLogo} alt="logo" className={`${classes.logo} mb-1 rounded-4`}/>
                    <span className={`font-weight-bold fs-01 mt-1`}>Dorsa NFT Collection</span>
                </div>
               {/* <span className={``}> Login to your Account</span>*/}

               <div className={` width-100 column jc-center ai-center`}>
                   <TextInput
                       label={t('username')}
                       type="text"
                       inputClass={`${classes.loginInput} width-90 mb-1`}
                       value={credential.username}
                       onchange={(e) => setCredential({...credential, username: e.target.value})}
                       alerts={[]}

                   />
                   <TextInput
                       label={t('password')}
                       type="password"
                       inputClass={`${classes.loginInput} width-90 mb-1 mt-2`}
                       value={credential.password}
                       onchange={(e) => setCredential({...credential, password: e.target.value})}
                       alerts={[]}
                   />
               </div>

                <span className={`${classes.errorText} text-blue fs-0-8`}>{loginError}</span>

                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} cursor-pointer`}
                    buttonTitle={t('login')}
                />

            </form>

            { loading && <div className={`position-absolute`}>
               <Loading/>
            </div>}

            <div className={`${classes.footer} flex mt-2`}>
                <span className={`fs-0-8 mr-1`}>Designed and developed by Nilin</span>
            </div>
        </div>
    );
};

export default Login;
