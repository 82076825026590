import React from 'react';
import classes from './Designer.module.css'
import {useNavigate, useParams} from "react-router-dom";
import {useGetDesigner} from "../../../../query";
import DesignersCard from "../Designers/Module/DesignersCard/DesignersCard";
import Icon from "../../../../components/Icon/Icon";
import * as RoutesName from "../../../../Routes/routes";
import DesignerAssets from "./Module/DesignerAssets/DesignerAssets";


const Designer = () => {

    console.log("one designer page rendered")

    const {id, name}= useParams()

    const navigate = useNavigate()

    const {data, isLoading, error} = useGetDesigner(id)




    return (
        <div className={`width-80 m-auto py-5 column jc-start ai-center min-height-100`}>

            <div className={`width-100 mb-3 fs-01`}>
                <Icon
                    iconName="icon-left-open fs-02"
                    iconClass={`cursor-pointer`}
                />
                <span className={`mr-05 cursor-pointer`} onClick={()=>navigate(RoutesName.Designers)}>All Designers</span>
            </div>
            <DesignersCard data={data} multi={false}/>

            <DesignerAssets designer={`${data?.network_id}-${data?.contract}`} designerName={data?.firstname}/>

        </div>
    );
};

export default Designer;
