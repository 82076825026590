import React from 'react';
import classes from './MaterialBox.module.css'

const MaterialBox = ({data}) => {
    return (
        <div className={`${classes.container} width-15 rounded-8 flex jc-center ai-center`}>
            {data}
        </div>
    );
};

export default MaterialBox;