import React from 'react';
import classes from './MaterialCard.module.css'

const MaterialCard = ({data}) => {

    if (!data) {
        return <div className={`width-100 flex jc-center ai-center my-10`}>
            <span className={`fs-04`}>Material data not available</span>
        </div>
    }

    return (
        <div className={`${classes.container} row jc-start ai-start wrap mt-3`}>
            {data.map( (name , index) =>
                <div key={index} className={`column jc-center ai-center rounded-8 width-23 py-2 mb-3 ${classes.card}`}>
                    <span className={` fs-03`}>{data[index]?.percent} %</span>
                    <span className={`${classes.divider} width-80 my-2`}></span>
                    <span className={`fs-0-9`}>{data[index]?.type}</span>
                </div>
            )}
        </div>
    );
};

export default MaterialCard;
