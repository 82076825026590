import {Toaster} from "react-hot-toast";
import React from "react";

const Toast = () => <Toaster position="top-right" toastOptions={
    {
        className: "rtl",
        style: {
            padding: "0.3vh 0.8vw 0.3vh 0.8vw",
            color: "white",
            lineHeight: "3vh",
            borderRadius: "8px",
            background: "var(--mainContent)",
        },
        success: {
            style: {
                background: "var(--darkGreen)",
            },
        },
        error: {
            style: {
                background: "var(--dyellow)",
                color: "#000"
            },
            iconTheme: {
                primary: '#777777',
                secondary: 'black',
            },
        },
        custom: {
            style: {
                background: "var(--Orange)",
            },
        },
    }}
/>

export default Toast;