import React from 'react';
import classes from "./ActionSheet.module.css"
import {NavLink} from "react-router-dom";
import * as RoutesName from "../../../../Routes/routes";

const ActionSheet = ({active, setActive}) => {
    return (
        <>
            {active && <div className={`${classes.wrapper}`} onClick={()=>setActive(prevstate => !prevstate)}/>}
            <div className={`${classes.container} column jc-center ai-center ${ active ? classes.show : classes.close}`}>


                <NavLink to={RoutesName.Home} onClick={()=>setActive(prevstate => !prevstate)} className={({isActive}) => isActive ? `cursor-pointer hover-text position-relative font-weight-bold height-100 width-100 flex jc-center  ai-center`: `cursor-pointer hover-text position-relative height-100 width-100 flex jc-center ai-center`}>
                    Home
                </NavLink>
                <NavLink to={RoutesName.Collections} onClick={()=>setActive(prevstate => !prevstate)} className={({isActive}) => isActive ? `cursor-pointer hover-text position-relative font-weight-bold height-100 width-100 flex jc-center  ai-center`: `cursor-pointer hover-text position-relative height-100 width-100 flex jc-center ai-center`}>
                    Collections
                </NavLink>
                <NavLink to={RoutesName.Designers} onClick={()=>setActive(prevstate => !prevstate)} className={({isActive}) => isActive ? `cursor-pointer hover-text position-relative font-weight-bold height-100 width-100 flex jc-center  ai-center`: `cursor-pointer hover-text position-relative height-100 width-100 flex jc-center ai-center`}>
                    Designers
                </NavLink>


            </div>

        </>
    );
};

export default ActionSheet;
