import React, {useEffect, useRef, useState} from 'react';
import classes from './AddItem.module.css'
import ScrollBar from "../../../../../../components/ScrollBar";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";
import * as Routes from "../../../../../../Routes/routes";
import Icon from "../../../../../../components/Icon/Icon";
import {Trans, useTranslation} from "react-i18next";
import useAuth from "../../../../../../Hooks/useAuth";
import {connectToMetamask, mint} from "../../../../../../utils";
import TextInput from "../../../../../../components/TextInput/TextInput";
import Button from "../../../../../../components/Button/Button";
import {images} from "../../../../../../assets/images/images";
import Loading from "../../../../../../components/Loading/Loading";
import {createItem, updateDB} from "../../../../../../api/auth";
import toast from "react-hot-toast";
import {getCollectionItems} from "../../../../../../api/web3";
import Web3 from "web3";
import RangeTextInput from "../../../../../../components/RangeTextInput/RangeTextInput";
import MATERIAL_TYPE from "../../../../../../lang/materialType";
import MATERIAL_TYPE_TR from "../../../../../../lang/materialTypeTr";
import {Assets} from "../../../../../../Routes/routes";


const AddItem = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();

    const {auth, setAuth} = useAuth();

    const location = useLocation();

    const [connect, setConnect] = useState(false);
    const [connectLoading, setConnectLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [minting, setMinting] = useState(false);
    const [mintStep, setMintStep] = useState("");


    const transactionHash = useRef("")
    //const polygonscanLink = useRef("")
    //const openseaLink = useRef("")
    const itemID = useRef("")


    const contractAddress = window.env.REACT_APP_CONTRACT_ADDRESS;
    const polyganScanURL = window.env.REACT_APP_POLYGONSCAN_URL;
    const exploreURL = window.env.REACT_APP_BLOCK_EXPLORER;
    const openSeaURL = window.env.REACT_APP_OPENSEA_URL;
    const network = window.env.REACT_APP_NETWORK;

    const env = window.env.REACT_APP_ENV


    const [itemData, setItemData] = useState({
        name: {value: "", error: []},
        designer: {value: "", error: []},
        birthday: {value: "", error: []},
        country: {value: "", error: []},
        description: {value: "", error: []},
        imageLink: {value: "", error: []},
        mediaLink: {value: "", error: []},
    });

    const [initialMaterial, setInitialMaterial] = useState({value: "", error: []});
    const [materialValue, setMaterialValue] = useState({value: 100, error: []});
    const [material, setMaterial] = useState([]);



    const newPercentageRef = useRef(100)

    console.log("newPercentageRef, " , newPercentageRef.current)
    console.log("material, " , material)


    useEffect(()=>{
         /*if (auth?.imageIpfsLink) setItemData({...itemData,imageLink: { value: auth?.imageIpfsLink, error: []}})
         if (auth?.animationIpfsLink) setItemData({...itemData,mediaLink: { value: auth?.animationIpfsLink, error: []}})*/

        let newItemData = {...itemData}
        if (auth?.animationIpfsLink) newItemData.mediaLink = { value: auth?.animationIpfsLink, error: []}
        if (auth?.imageIpfsLink) newItemData.imageLink = { value: auth?.imageIpfsLink, error: []}
        setItemData(newItemData)

    },[location])





    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="items.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...itemData,
            [e.target.dataset.name]: {...itemData[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setItemData(prevState)
    }

    const isFormValid = () => {
        let inputs = {...itemData}

        const hasError = Object.values(itemData).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            console.log(key)
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="items.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setItemData(inputs);
        return !isEmpty;
    }

    const isConnected = (accounts) => {
      if (accounts.length === 0) setConnect(false)
    }

    useEffect(() => {

        if (typeof window.ethereum !== 'undefined' && window.ethereum._state.accounts.length){
            setConnect(true)
            window.ethereum.on('accountsChanged', isConnected);
        }
        setFirstLoading(false)

    }, []);


    /*const item = {
        name: itemData.name.value,
        designer: itemData.designer.value,
        birthday: itemData.birthday.value,
        country: itemData.country.value,
        description: itemData.description.value,
        image: itemData.imageLink.value,
        animation_url: itemData.mediaLink.value,
        material: material,
    }

    console.log("item before ", item)*/


    const submit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) return false;

        setMinting(true)

        const countNft = await getCollectionItems();

        /*console.log("countNft", countNft)*/

        const findMax = (countNft) => {
            let maxValue = null;
            for (let i = 0; i < countNft.length; i++) {

                const currentvalue = Web3.utils.toNumber(countNft[i].Id)

                if ( currentvalue > maxValue) {
                    maxValue = currentvalue;
                }
            }
            return maxValue;
        }
        let maxValue = findMax(countNft);

        /*console.log("maxValue", maxValue)*/

        const item = {
            name: itemData.name.value,
            designer: itemData.designer.value,
            birthday: itemData.birthday.value,
            country: itemData.country.value,
            description: itemData.description.value,
            image: itemData.imageLink.value,
            animation_url: itemData.mediaLink.value,
            material: material,
        }


        await createItem(item , auth.token)
            .then(async (res) => {



                setMintStep("CREATED")

                const mintData = {
                    cid: `ipfs://${res?.data?.data?.value?.cid}`,
                    address: window.ethereum._state.accounts[0],
                    id: String((maxValue + 1)),
                }

                await mint(mintData).then( async (res)=> {
                    setMintStep("MINTED")

                    console.log("res mint", res)

                    //polygonscanLink.current = `https://mumbai.polygonscan.com/address/${res?.transactionHash}`
                    transactionHash.current = res?.transactionHash
                    //openseaLink.current = `https://testnets.opensea.io/assets/mumbai/${contractAddress}/${mintData?.id}`
                    itemID.current = mintData?.id


                    await updateDB(auth.token).then(()=>{

                    }).catch((e)=>{
                        console.log("error", e)
                    })




                }).catch((err)=>{
                    toast.error("Failed to Mint, try again!");
                    setMintStep("")
                    setMinting(false)
                    console.log("err" , err)
                })

            })
            .catch((err) => {
                toast.error("Failed to upload file, try again!");
                setMintStep("")
                setMinting(false)
                console.log("err" , err)
            })
            .finally(() => {

            });

        // anim    ipfs://bafybeid3pcof3ekxjczslxhofgkfmxzhmuyqs6t6jyxevk7fwlnx2zp4ta
        // image   ipfs://bafybeier54oigxptlqkxgv7pdduxhs44ucqq5wfwoxqparp7xxi5sx3oxm




    };
    const netmaskConnectionHandler = (accounts) => {
        if (accounts.length === 0) setConnect(false)
    }

    const connectFunc = async () => {
        setConnectLoading(true)
        if (await connectToMetamask(netmaskConnectionHandler)) {
            setConnect(true)
        } else {
            // toast.error("Failed to connect to Metamask");
        }
        setConnectLoading(false)
    }
    const backAfterMint = async () => {
        setMintStep("")
        setMinting(false)
        newPercentageRef.current = 100
        setMaterialValue({value: 100, error: []})
        setInitialMaterial({value: "", error: []})
        setMaterial([])
        setAuth({...auth,
            imageIpfsLink: "",
            animationIpfsLink: "",
        })
        setItemData({
            name: {value: "", error: []},
            designer: {value: "", error: []},
            birthday: {value: "", error: []},
            country: {value: "", error: []},
            description: {value: "", error: []},
            imageLink: {value: "", error: []},
            mediaLink: {value: "", error: []},
        })
    }


    const navigateToUploadPage = (type) => {
        navigate(Routes.UploadFile + "/" + type)
    }


    const copyToClipboard = () => {
        navigator.clipboard.writeText(transactionHash.current)
        toast.success("Copied");
    };

    const mintStepTitleHandler = () => {
        if (mintStep === "MINTED") return <div className={`column jc-center ai-center`}>
            <div className={`row text-green mb-1`}>
                <span className={`ml-05`}>Created</span>
                <Icon
                    iconName="icon-ok-3 fs-04 "
                    iconClass={`cursor-pointer mr-05`}
                    onClick={()=>navigate(RoutesName.CreateItem)}
                />
            </div>
            <div className={`row text-green mb-1`}>
                <span className={`ml-05`}>Minted</span>
                <Icon
                    iconName="icon-ok-3 fs-04 "
                    iconClass={`cursor-pointer mr-05`}
                    onClick={()=>navigate(RoutesName.CreateItem)}
                />
            </div>

            <span className={`fs-02 my-2 mb-3`}>Item {itemData.name.value} with id #<span>{itemID.current}</span> mint successfully</span>
            <div className={`width-100 row my-3`}>
                <div className={`width-33 column jc-center ai-center`}>
                    <img src={images.dorsaLogo} alt="boxes" className={`${classes.linkImage}`}/>
                    <span className={`my-2`}>Dorsa</span>
                    <Icon iconName="icon-popup-link-icon flex" iconClass={` cursor-pointer hover`} title={`Item #${itemID.current} in Dorsa`} onClick={()=>window.open(`${RoutesName.Assets + itemID.current}`)}/>
                </div>
                <div className={`width-33 column  column jc-center ai-center ${classes.etherscan}`}>
                    <img src={images.etherscan} alt="etherscan" className={`${classes.linkImage}`}/>
                    <span className={`my-2`}>Etherscan</span>
                    <Icon iconName="icon-popup-link-icon flex" iconClass={` cursor-pointer hover`} title={`Item #${itemID.current} in Etherscan`} onClick={()=>window.open(`${exploreURL}/tx/${transactionHash.current}`)}/>
                </div>
                <div className={`width-33 column  column jc-center ai-center`}>
                    <img src={images.openSea} alt="opensea" className={`${classes.linkImage}`}/>
                    <span className={`my-2`}>Opensea</span>
                    <Icon iconName="icon-popup-link-icon flex" iconClass={` cursor-pointer hover`} title={`Item #${itemID.current} in Opensea`} onClick={()=>window.open(`${openSeaURL}/assets/${network}/${contractAddress}/${itemID.current}`)}/>
                </div>
            </div>
            <div className={`container row jc-center ai-center my-2`}>
                <Icon iconName="icon-copy-link-icon flex" iconClass={`ml-025 cursor-pointer hover`} onClick={() => copyToClipboard()} title={`Click to copy Transaction Hash`}/>
                <span className={`fs-0-8 mr-025`} >{transactionHash.current}</span>
            </div>

            <Button
                type="button"
                buttonClass={`${classes.backButton} cursor-pointer mt-2`}
                buttonTitle="Back"
                onClick={()=>backAfterMint()}
            />


        </div>
        if (mintStep === "CREATED") return <div className={`column jc-center ai-center`}>
            <div className={`row text-green mb-1`}>
                <span className={`ml-05`}>Created</span>
                <Icon
                    iconName="icon-ok-3 fs-04 "
                    iconClass={`cursor-pointer mr-05`}
                    onClick={()=>navigate(RoutesName.CreateItem)}
                />
            </div>
            <span className={`mt-1`}>Minting...</span>
        </div>
        if (mintStep === "") return <div>Creating...</div>
    }

    const materialHandler = (val) => {

        setInitialMaterial({value: val, error: []})

    }


    const customStyles = {
        menu: (provided) => ({
            ...provided,
            border: '0.18vh solid #e7e7e7',
            padding: '1vh 0.5vw',
            direction: 'ltr',
            zIndex: 2
        }),
        multiValue: (provided) => ({
            ...provided,
            padding: '0 0.5vw',
            margin: '0.5vh 0.25vw',
            direction: 'ltr',
            zIndex: 2
        }),
    }

    const addMaterial = () => {

        if (initialMaterial.value.length <= 0) return setInitialMaterial({...initialMaterial, error: ["Select material"]})
        if (materialValue.value > newPercentageRef.current) {
            return setMaterialValue({...materialValue, error: [`The maximum percentage can be ${newPercentageRef.current}`]})
        }
        if (newPercentageRef.current === 0 ) {
            return setMaterialValue({...materialValue, error: [`The percentage of materials cannot be more than 100`]})
        }
        const newMaterialArray = [...material]

        newMaterialArray.push({
            type: initialMaterial.value.label,
            percent: materialValue.value
         })

        setMaterial(newMaterialArray)

        /*let percentage = 100*/
/*
        console.log("material before for", material)

        const newPercentage = newMaterialArray.reduce((accumulator, currentValue) => accumulator - currentValue?.percent, percentage);

        newPercentageRef.current = newPercentage

        console.log("newPercentage", newPercentage)

       /!* for ( let i = 0; i < newMaterialArray.length ; i++) {
            percentage -= newMaterialArray[i]?.percent
        }

        console.log("percentage", percentage)*!/


        setMaterialValue({...materialValue, value: newPercentage  })*/


    }

    useEffect(()=>{
        let percentage = 100
        const newMaterialArray = [...material]
        const newPercentage = newMaterialArray.reduce((accumulator, currentValue) => accumulator - currentValue?.percent, percentage);

        newPercentageRef.current = newPercentage

        setMaterialValue({...materialValue, value: newPercentage  })

    },[material])

    const removeMaterial = (index, percent) => {

        /*console.log("percent", percent)
        console.log("percent tpe of", typeof percent)*/

        const newMaterialArray = [...material]

        newMaterialArray.splice(index, 1)

        setMaterial(newMaterialArray)
/*        newPercentageRef.current += parseInt(percent)

        const newMaterialValue = materialValue.value + parseInt(percent)
        setMaterialValue({value: newMaterialValue, error: [] })*/

    }

    const rangeTextInputHandler = (e) => {

      /*  console.log("e.target.value :", e.target.value)
        console.log("materialValue.value :", materialValue.value)*/

        if ("") {

        }

        setMaterialValue({ value: e.target.value, error: []})
    }

                                                                     
    const content = () => {
        if (connect) {
            return <>
                <Outlet/>
                <div className={`width-100 mt-8 row jc-between wrap`}>
                    <TextInput
                        label={t('items.name')}
                        data-name="name"
                        data-type="input"
                        data-min={2}
                        type="text"
                        placeholder={t('items.phName')}
                        inputClass={`width-48 my-1`}
                        value={itemData.name.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.name.error}
                    />
                    <TextInput
                        label={t('items.designer')}
                        data-name="designer"
                        data-type="input"
                        data-min={2}
                        type="text"
                        placeholder={t('items.phDesigner')}
                        inputClass={`width-48 my-1`}
                        value={itemData.designer.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.designer.error}
                    />
                    <TextInput
                        label={t('items.birthday')}
                        data-name="birthday"
                        data-type="input"
                        data-min={2}
                        type="text"
                        placeholder={t('items.phBirthday')}
                        inputClass={`width-48 my-1`}
                        value={itemData.birthday.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.birthday.error}
                    />
                    <TextInput
                        label={t('items.country')}
                        data-name="country"
                        data-type="input"
                        data-min={2}
                        type="text"
                        placeholder={t('items.phCountry')}
                        inputClass={`width-48 my-1`}
                        value={itemData.country.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.country.error}
                    />
                    <span className={`${classes.divider} width-100 mb-2 mt-4`}></span>

                    <div className={`width-100 my-2  row jc-between ai-start`}>



                       {/* <label className={`font-weight-bold fs-01`}>{t('items.material')}</label>*/}

                       <div className={`column jc-start ai-start width-48`}>
                           <TextInput
                               label={t('items.material')}
                               select={true}
                               subLabel="First, select the desired material and then specify its percentage, then add it"
                               type="text"
                               data-name="material"
                               data-type="text"
                               alerts={initialMaterial.error}
                               onchange={materialHandler}
                               value={initialMaterial.value}
                               inputClass={`width-100 ${classes.smallLead}`}
                               options={MATERIAL_TYPE.map(w => ({value: w, label: MATERIAL_TYPE_TR[w]}))}
                               isMulti={false}
                               isRtl={true}
                               closeMenuOnSelect={true}
                               styles={customStyles}
                               placeholder="Select"
                           />

                           <RangeTextInput
                               label="Percentage"
                               data-name="name"
                               data-type="input"
                               data-min={2}
                               type="range"
                               placeholder="Enter Material percentage"
                               inputClass={`width-100 my-2`}
                               value={materialValue.value}
                               onchange={(e) => rangeTextInputHandler(e)}
                               alerts={materialValue.error}
                           />


                           <Button
                               type="button"
                               buttonClass={`${classes.addButton} cursor-pointer width-30 mt-3`}
                               buttonTitle="Add"
                               onClick={()=>addMaterial()}
                           />
                       </div>
                        <div className={`width-48 flex jc-between ai-start wrap`}>
                            {material.map( (name , index) => <div key={index} className={`row jc-center ai-center rounded-8 width-47 ml-1 mb-2  py-1 ${classes.badge}`}  style={{backgroundColor: "#e7e7e7"}}>
                                <span className={`width-85 text-center px-2 `}>{name.type}  ( {name.percent} % )</span>
                                <Icon
                                    iconName="icon-cancel-1 fs-03"
                                    iconClass={`cursor-pointer hover-text width-15`}
                                    onClick={()=>removeMaterial(index, name?.percent)}
                                />

                            </div>)}

                        </div>



                    </div>

                    <span className={`${classes.divider} width-100 mb-4 mt-2`}></span>

                    <TextInput
                        label={t('items.description')}
                        data-name="description"
                        data-type="input"
                        data-min={5}
                        type="text"
                        textarea={true}
                        placeholder={t('items.phDescription')}
                        subLabel={t('items.descriptionSublabel')}
                        inputClass={`width-100 my-3 ${classes.inputClass}`}
                        value={itemData.description.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.description.error}
                    />


                    <TextInput
                        label={t('items.imageLink')}
                        data-name="imageLink"
                        data-type="input"
                        data-min={10}
                        type="text"
                        placeholder={t('items.phImageLink')}
                        subLabel={t('items.imageLinkSublabel')}
                        inputClass={`width-48 my-1`}
                        value={itemData.imageLink.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.imageLink.error}
                        sideContent={<div className={`row jc-center ai-center mr-025 cursor-pointer hover-text`} onClick={()=>navigateToUploadPage("image")}>
                            <Icon
                                iconName="icon-plus-circle fs-02"
                                iconClass={`cursor-pointer ml-025`}
                            />
                            <span>Upload File</span>
                        </div>}
                    />
                    <TextInput
                        label={t('items.mediaLink')}
                        data-name="mediaLink"
                        data-type="input"
                        data-min={10}
                        type="text"
                        placeholder={t('items.phMediaLink')}
                        subLabel={t('items.mediaLinkSublabel')}
                        inputClass={`width-48 my-1`}
                        value={itemData.mediaLink.value}
                        onchange={(e) => inputHandler(e)}
                        alerts={itemData.mediaLink.error}
                        sideContent={<div className={`row jc-center ai-center mr-025 cursor-pointer hover-text`} onClick={()=>navigateToUploadPage("3d-model")}>
                            <Icon
                                iconName="icon-plus-circle fs-02"
                                iconClass={`cursor-pointer ml-025`}
                            />
                            <span>Upload File</span>
                        </div>}
                    />

                    {/*<div className={`column width-48`}>
                      <span className={`font-weight-bold fs-01`}>{t('items.image')}</span>
                      <span className={`${classes.subLabel} fs-0-9 text-gray mb-2`}>{t('items.imageSublabel')}</span>
                      <ImageInput
                          zoneCustomClass={classes.zoneBox}
                          title={<img src={images.image} alt="" className={`${classes.zoneLogo}`}/>}
                          onchange={(url) => setItemData({...itemData, image: {...itemData.image, value: url, error: []}})}
                      />
                  </div>

                  <div className={`column width-48`}>
                      <span className={`font-weight-bold fs-01`}>{t('items.media')}</span>
                      <span className={`${classes.subLabel} fs-0-9 text-gray mb-2`}>{t('items.mediaSublabel')}</span>
                      <ImageInput
                          zoneCustomClass={classes.zoneBox}
                          title={<img src={images.media} alt="" className={`${classes.zoneLogo}`}/>}
                          onchange={(url) => setItemData({...itemData, image: {...itemData.image, value: url, error: []}})}
                      />
                  </div>*/}



                </div>


                <div className={`width-100 mt-8`}>
                    <Button
                        type="submit"
                        buttonClass={`${classes.thisButton} cursor-pointer`}
                        buttonTitle={t('items.create')}
                    />
                </div>
            </>
        }
        else return <div className={`width-100 mt-8 flex jc-center ai-center column py-10`}>
            <img src={images.metamask} alt="" className={`${classes.metaIcon} mb-3`} />
            <span className={`my-5`}>For creating new item Connect to MetaMask</span>
            {connectLoading ? <Loading/> : <Button
                type="button"
                buttonClass={`${classes.thisButtonMeta} cursor-pointer`}
                buttonTitle={t('items.connect')}
                onClick={()=>connectFunc()}
            />}
        </div>

    }
    return (
        <>
            { firstLoading ? <Loading isFullPage={true}/> : <div className={`${classes.container} position-relative `}>
                <ScrollBar>
                    <form onSubmit={(e)=>submit(e)} className={`width-70 m-auto py-7 ${minting && 'filter-blur-4'}`}>

                        <div className={`row jc-between ai-center width-100`}>
                            <div className={`row jc-start ai-center`}>

                                <span className={`${classes.line} ml-05 rounded-10`}></span>
                                <Link to={Routes.Home} className={`cursor-pointer hover-text mx-05`}>
                                    <Icon
                                        iconName=" icon-home fs-03"
                                        iconClass={`cursor-pointer`}
                                    />
                                </Link>
                                <span className={` font-weight-bold fs-06 mr-05`}>Create New Item</span>

                            </div>
                            <div className={`row jc-end ai-center`}>

                                <span className={`mx-05 fs-01`}>{auth.display_name}</span>

                                <Link to={Routes.Login} className={`cursor-pointer hover-text`}>

                                    <Icon
                                        iconName=" icon-logout text-blue fs-03"
                                        iconClass={`cursor-pointer`}
                                    />

                                </Link>{/*
                            <img src={images.dorsaLogo} alt="logo" className={`${classes.logo} rounded-4 mr-5`}/>*/}
                            </div>



                        </div>

                        {content()}




                    </form>
                </ScrollBar>
                { minting && <div className={`position-absolute ${classes.showLoading} flex jc-center ai-center`}>
                    <div className={`${classes.loadingContent} column jc-center ai-center py-3 px-2 rounded-8`}>
                        { mintStep !== "MINTED" && <img src={images.dorsaLoading} alt="loading..."/>}
                        <span className={`fs-01`}>
                                {mintStepTitleHandler()}
                            </span>
                    </div>
                </div>}
            </div>}

        </>
    );
};

export default AddItem;
