import {Navigate, Outlet} from "react-router-dom";

import * as RoutesName from "../../Routes/routes";
import useAuth from "../../Hooks/useAuth";

const RequireAuth = () => {
    const { auth } = useAuth();
    return (
        auth?.isLogin
            ? <Outlet />
            : <Navigate to={RoutesName.Login} replace />
    );
}

export default RequireAuth;