import React from 'react';
import {isBrowser, isMobile, isTablet} from "react-device-detect";
import Browser from "./Browser/Browser";
import Tablet from "./Tablet/Tablet";
import Mobile from "./Mobile/Mobile";

const Main = () => {

    console.log("isTablet" , isTablet)
    console.log("isMobile" , isMobile)

    const detectDevice = () => {
        if (isBrowser) return <Browser/>
        if (isTablet) return <Tablet/>
        if (isMobile) return <Mobile/>
    }

    return detectDevice();
};

export default Main;
