import abi from '../abi'
import Web3 from "web3";

export const checkHasMetaMask = () => typeof window.ethereum !== 'undefined'

export const getCurrentWalletAddress = () => window?.ethereum?._state?.accounts[0]

export const getCurrentChainID = () => window?.ethereum?.networkVersion

export const switchChain = (ChainID) => window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{chainId: Web3.utils.toHex(ChainID)}]
});

export const addPolygonTestnetChain = () => window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
        {
            chainName: 'Polygon Testnet',
            chainId: Web3.utils.toHex(80001),
            nativeCurrency: {name: 'MATIC', decimals: 18, symbol: 'MATIC'},
            rpcUrls: ['https://rpc-mumbai.maticvigil.com/']
        }
    ]
});
export const addPolygonChain = () => window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
        {
            chainName: 'Polygon',
            chainId: Web3.utils.toHex(137),
            rpcUrls: ['https://polygon-rpc.com'],
            blockExplorer: "https://polygonscan.com",
            ticker: "MATIC",
            tickerName: "Matic",
        }
    ]
});

export const getAccounts = () => window.ethereum.request({method: 'eth_requestAccounts', params: []})

export const mint = async (metaData , contractAddress ) => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(abi, contractAddress);
    return contract.methods.mintNFT(metaData.address, metaData.cid, Web3.utils.toBN(metaData.id).toString()).send({from: metaData.address})
}