export const Home = "/";
export const Dashboard = "/dashboard";
export const Designers = "/designers/";
export const Accounts = "/dashboard/accounts";
export const Material = "/dashboard/material";
export const Collections = "/collections/";
export const CreatCollection = "/collection/create";
export const CreateAsset = "/asset/create/";
export const CreateDesigner = "/designer/create/";
export const UpdaeDesigner = ( id=":id", name= ":name") =>  `/designer/${id}/${name}/update`;
export const ShowDesigner = ( id=":id", name= ":name") =>  `/designer/${id}/${name}`;

export const Assets = ( contractAddress=":contractAddress", name= ":name") => `/collections/${contractAddress}/${name}/assets/`;
export const Login = "/login";
export const CreateItem = "/create-item";
export const UploadFilee = "/create-item/upload-Filee";
export const UploadFile = "/upload-File";
export const UploadFileRelative = "/upload-Filee";
