import React from 'react';
import AssetsCard from "./Module/AssetsCard/AssetsCard";
import {useGetCollectionAssets} from "../../../../query";
import Loading from "../../../../components/Loading/Loading";
import Title from "../../../../components/Title/Title";
import {useNavigate, useParams} from "react-router-dom";
import classes from "../Collections/Collections.module.css";
import * as RoutesName from "../../../../Routes/routes";
import Icon from "../../../../components/Icon/Icon";
import useAuth from "../../../../Hooks/useAuth";
import CreateAsset from "./Module/CreateAsset/CreateAsset";

const Assets = ({creator}) => {

    const navigate = useNavigate();
    const {auth} = useAuth();
    const {address, name} = useParams()

    const {data , isLoading , error} = useGetCollectionAssets(address)

    const assets = data?.assets && data?.assets


    const content = () => {
        if (error) return <div className={`width-100 flex jc-center ai-center my-10`}>
            <span className={`fs-04`}>Failed to get items!</span>
        </div>

        if (isLoading) return <div className={`width-100 column jc-center ai-center my-10`}>
            <Loading/>
            <span className={`mt-5`}>Loading...</span>
        </div>

        if (data.length === 0 ) return <div className={`width-100 column jc-center ai-center my-10`}>
            <span className={`mt-5`}>No Asset!</span>
        </div>

        return assets.map((asset , index) => <AssetsCard data={asset} index={index} key={asset?.token_id}/>)

    }

    return (
        <div className={`width-80 m-auto `}>

            {(auth?.isLogin && (auth?.account === creator))&&
                <div className={`row mt-5 ${classes.createCollection} cursor-pointer hover-text rounded-8 py-2 px-1`}
                     onClick={() => navigate(RoutesName.CreateAsset + address + "/" + name)}
                >
                    <Icon
                        iconName="icon-plus-circle fs-20"
                        iconClass={`cursor-pointer ml-025`}
                    />
                    <div className={`fs-01`}>Create New Asset</div>
                </div>

            }

            <div className={`row jc-between ai-center mb-3 mt-4`}>
                <Title title="Assets"/>
                <span className={`fs-02`}>{assets?.length}</span>
            </div>
            <div className={`width-100 row jc-start wrap`}>
                {content()}
            </div>
        </div>
    );
};

export default Assets;
