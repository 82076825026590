import React from 'react';
import classes from './Title.module.css'

const Title = ({title}) => {
    return (
        <div className={`row jc-start ai-center`}>
            <span className={`${classes.line} rounded-10`}/>
            <span className={` font-weight-bold fs-01`}>{title}</span>
        </div>
    );
};

export default Title;
