import React, {useState} from 'react';
import Title from "../../../../components/Title/Title";
import * as RoutesName from "../../../../Routes/routes";
import Icon from "../../../../components/Icon/Icon";
import {useGetCollectionByOwnerAddress} from "../../../../query";
import CollectionsCard from "./Module/CollectionsCard/CollectionsCard";
import {useNavigate} from "react-router-dom";
import Loading from "../../../../components/Loading/Loading";
import useAuth from "../../../../Hooks/useAuth";
import classes from "./Collections.module.css"
import Button from "../../../../components/Button/Button";
import CreateCollection from "./Module/CreateCollection/CreateCollection";
import MetamaskConnection from "../../Layout/Header/Module/MetamaskConnection/MetamaskConnection";
import {images} from "../../../../assets/images/images";


const Collections = ({slice}) => {
    const navigate = useNavigate();
    const {auth} = useAuth();
    const [single, setSingle] = useState(false);

    const {data: collections, isLoading, error} = useGetCollectionByOwnerAddress()


    const content = () => {

        if (error) return <div className={`width-100 flex jc-center ai-center my-10`}>
            <span className={`fs-04`}>Failed to get Collections!</span>
        </div>

        if (isLoading) return <div className={`width-100 column jc-center ai-center my-10`}>
            <Loading/>
            <span className={`mt-5`}>Loading...</span>
        </div>
        if (single && !auth?.isConnected) return <div className={`width-100 column jc-center ai-center my-10`}>
            <img src={images.metamask} className={``} style={{ width: "5.7%" }} alt=""/>
            <span className={`my-4 fs-01`}>Connect your wallet with Metamask to view your collections...</span>
            <span className={``}>
                <MetamaskConnection/>
            </span>
        </div>

        if (collections?.filter((c)=>{
            if (!single) return true;
            return c.creator === auth?.account
        }).length === 0) return <div className={`width-100 flex jc-center ai-center my-10`}>
            <span className={`fs-04`}>No Collection!</span>
        </div>

        return collections?.filter((c)=>{
            if (!single) return true;
            return c.creator === auth?.account
        }).map((collection, index) => {
            if (slice && index > slice) return
            return <CollectionsCard data={collection} index={index} key={index}/>
        })

    }


    return (
        <div className={`width-80 m-auto min-height-100`}>
            {auth?.isLogin &&
                <div className={`row mt-5 ${classes.createCollection} cursor-pointer hover-text rounded-8 py-2 px-1`}
                     onClick={() => navigate(RoutesName.CreatCollection)}
                >
                    <Icon
                        iconName="icon-plus-circle fs-20"
                        iconClass={`cursor-pointer ml-025`}
                    />
                    <div className={`fs-01`}>Create New Collection</div>
                </div>

            }
            <div className={`row jc-between ai-center mb-3 mt-5`}>
                {(auth?.isLogin && !slice) ? <div className={`row`}>
                    <Button
                        type="button"
                        buttonClass={`${classes.thisButton} cursor-pointer px-2 ml-1 ${!single && classes.active}`}
                        buttonTitle="All Collections"
                        onClick={() => setSingle(false)}
                    />
                    <Button
                        type="button"
                        buttonClass={`${classes.thisButton} cursor-pointer px-2 mr-1 ${single && classes.active}`}
                        buttonTitle="My Collections"
                        onClick={() => setSingle(true)}
                    />

                </div> : <Title title="Collections"/>}


                {slice ? <div className={`row jc-end ai-center hover-text cursor-pointer`}
                              onClick={() => navigate(RoutesName.Collections)}>
                    <span className={`ml-025`}>View All Collections</span>
                    <Icon
                        iconName="icon-right-open fs-01"
                        iconClass={`cursor-pointer`}
                    />
                </div> : <span className={`fs-02`}>{collections?.filter((c)=>{
                    if (!single) return true;
                    return c.creator === auth?.account
                })?.length}</span>}
            </div>
            <div className={`width-100 row jc-start wrap mb-10`}>
                {content()}
            </div>
        </div>
    );
};

export default Collections;
