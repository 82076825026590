import React from 'react';
import classes from './Header.module.css'
import {Link, NavLink, useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../Routes/routes";
import useAuth from "../../../../Hooks/useAuth";
import Icon from "../../../../components/Icon/Icon";
import jwtDecode from "jwt-decode";
import Countdown from "react-countdown";
import {Logout} from "../../../../api/auth";
import MetamaskConnection from "./Module/MetamaskConnection/MetamaskConnection";

const Header = () => {

    const navigate = useNavigate()
    const { auth ,setAuth } = useAuth();


    // const tokenExpire = localStorage.getItem('token');
    const jwt = auth.isLogin && jwtDecode(auth?.token)


    const onComplete = async () => {

        console.log("auth?.token", auth?.token)

        await Logout(auth?.token)
            .then(async (res) => {
                setAuth({
                    isLogin: false,
                    token: null,
                    display_name: null,
                    imageIpfsLink: "",
                    animationIpfsLink: "",
                })
                localStorage.removeItem('token')
                return navigate(RoutesName.Home);
            })
            .catch((err) => {
                console.log("err", err)
            })
            .finally(() => {
            });

    }



    return (
        <div className={`${classes.header}  `}>

              <div className={`row jc-between ai-center height-100 width-80 m-auto `}>


                  <div className={`row jc-start ai-center width-38`}>
                      {/*<img src={images.dorsaBrandLogoYellow} alt="" className={`${classes.logo} rounded-4 ml-1`}/>*/}

                      <Link to={RoutesName.Home} className={` font-weight-bold fs-04`}>NlN NFT</Link>
                  </div>

                  <div className={`height-100 row ai-center fs-01 ${auth?.isLogin ? "width-24 jc-center" : "width-50 jc-end"}  `}>
                      <NavLink to={RoutesName.Home} className={({isActive}) => isActive ? `cursor-pointer hover-text ${auth.isLogin ? "mx-1" : "mr-3"}  height-100 row jc-center ai-center position-relative ${classes.selected}`: `cursor-pointer hover-text ${auth.isLogin ? "mx-1" : "mr-3"} height-100 row jc-center ai-center position-relative`}>
                          Home
                          <span className={`width-100 position-absolute`}/>
                      </NavLink>
                      { auth.isLogin && <NavLink to={RoutesName.Dashboard} className={({isActive}) => isActive ? `cursor-pointer hover-text ${auth.isLogin ? "mx-1" : "mr-3"} height-100 row jc-center ai-center position-relative ${classes.selected}`: `cursor-pointer hover-text ${auth.isLogin ? "mx-1" : "mr-3"} height-100 row jc-center ai-center position-relative`}>
                          Dashboard
                          <span className={`width-100 position-absolute`}/>
                      </NavLink>}
                      { !auth.isLogin && <NavLink to={RoutesName.Collections} className={({isActive}) => isActive ? `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative ${classes.selected}`: `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative`}>
                          Collections
                          <span className={`width-100 position-absolute`}/>
                      </NavLink>}
                      {/*<NavLink to={RoutesName.Assets}
                               //className={`mr-3 text-gray height-100 row jc-center ai-center`}
                               className={({isActive}) => isActive ? `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative ${classes.selected}`: `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative`}
                      >
                          Assets
                          <span className={`width-100 position-absolute`}/>
                      </NavLink>*/}
                      { !auth.isLogin && <NavLink to={RoutesName.Designers}
                                                  className={({isActive}) => isActive ? `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative ${classes.selected}`: `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative`}
                      >
                          Designers
                          <span className={`width-100 position-absolute`}/>
                      </NavLink> }
                      {/*{ !auth.isLogin && <NavLink to={"#"} className={`mr-3 text-gray height-100 row jc-center ai-center`}>
                          Explore
                          <span className={`width-100 position-absolute`}/>
                      </NavLink>}*/}
                      {/*{ auth.isLogin && <NavLink to={RoutesName.Accounts}
                            //className={`cursor-pointer hover-text mr-3 height-100 row jc-center ai-center`}
                            className={({isActive}) => isActive ? `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative ${classes.selected}`: `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative`}
                      >
                          Accounts
                          <span className={`width-100 position-absolute`}/>
                      </NavLink>}*/}
                      {/*{ auth.isLogin && <NavLink to={RoutesName.Material}
                            //className={`cursor-pointer hover-text mr-3 height-100 row jc-center ai-center`}
                            className={({isActive}) => isActive ? `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative ${classes.selected}`: `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative`}
                      >
                          Material
                          <span className={`width-100 position-absolute`}/>
                      </NavLink>}*/}
                      { !auth.isLogin && <NavLink to={RoutesName.Login}
                            //className={`cursor-pointer hover-text mr-3 height-100 row jc-center ai-center`}
                            className={({isActive}) => isActive ? `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative ${classes.selected}`: `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative`}
                      >
                          Manage
                          <span className={`width-100 position-absolute`}/>
                      </NavLink>}
                  </div>

                  { auth.isLogin && <div className={`height-100 row jc-end ai-center  width-38`}>

                      <MetamaskConnection/>

                      <div className={`flex row jc-center mx-1  width-15`}>
                         <Countdown
                              date={parseInt(jwt.exp) * 1000 }
                              renderer={props => <div className={ `${props.minutes === 0 && "text-orange"} row direction-ltr`}> {props.minutes} : {props.seconds}</div>}
                              onComplete={() => onComplete()}
                          />
                      </div>


                      <Icon
                          iconName=" icon-logout text-blue fs-03 flex"
                          iconClass={`cursor-pointer hover-text`}
                          onClick={() => onComplete()}
                      />

                  </div>}
              </div>

        </div>
    );
};

export default Header;
