import React from 'react';
import classes from './Asset.module.css'
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getIpfsUrl} from "../../../../utils";
import Icon from "../../../../components/Icon/Icon";
import {images} from "../../../../assets/images/images";
import Button from "../../../../components/Button/Button";
import ModelSection from "./Module/ModelSection/ModelSection";
import {useGetCollectionAssetById} from "../../../../query/hooks/useGetCollectionAssetById";
import Loading from "../../../../components/Loading/Loading";
import Material from "./Module/Material/Material";
import web3 from "web3";
import toast from "react-hot-toast";
import DesignerMiniCard from "./Module/DesignerMiniCard/DesignerMiniCard";
import * as RoutesName from "../../../../Routes/routes";

const Asset = () => {

    const {t} = useTranslation();
    const {id, contractAddress, name} = useParams()
    const navigate = useNavigate();

    const {data, isLoading, error} = useGetCollectionAssetById(contractAddress, contractAddress, id)

    const asset = data?.assets[0] && data?.assets[0]
    const detail = data?.network && data?.network
    const owner = (!(data?.owner?.error) && data?.owner !== null) && data?.owner[0]?.owner

    const copyToClipboard = (value, e) => {
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success("Copied");
    }

    if (isLoading) return <div className={`width-100 column jc-center ai-center min-height-100`}>
         <Loading/>
         <span className={`mt-5`}>Loading...</span>
     </div>
     if (error) return <div className={`min-height-100 width-80 m-auto pt-3 pb-5 text-center my-10 flex jc-center ai-center fs-01`}>
        Failed to get Asset!
    </div>

    return (
        <div className={`min-height-100 pt-2`}>
                <div className={`width-80 m-auto pt-3 pb-5 `}>
                    <div className={`width-100 row jc-between ai-center`}>
                        <div
                            className={`${classes.imageBox} width-30 flex jc-center ai-center rounded-8 py-2 px-2 position-relative`}>
                            <img src={getIpfsUrl(asset?.image_original_url)} alt=""/>

                            <div className={`${classes.subImage} position-absolute`}>
                                Asset {id} from <span className={`cursor-pointer hover-text`} onClick={()=>navigate(RoutesName.Collections + contractAddress +"/"+ name)}>{asset?.collection?.name}</span>
                            </div>

                        </div>
                        <div className={`${classes.infoBox} width-65 row jc-between ai-start`}>
                            <div className={`column width-70 jc-between ai-start pl-2 height-100 pt-1`}>
                                <div className={`width-100 row jc-between ai-center`}>
                                    <span className={`font-weight-bold fs-05`}>{asset?.name}</span>
                                    <span className={`text-gray fs-0-9`}>NFC Tag: {detail?.nfcTag ? detail?.nfcTag : "---"}</span>
                                </div>

                                <span className={``}>{asset?.seaport_sell_orders !== null ?
                                    <span><span
                                        className={`fs-05 font-weight-bold`}>{web3.utils.fromWei(asset?.seaport_sell_orders[0]?.current_price)}</span><span
                                        className={`mr-025`}> ETH</span></span> : "---"}</span>

                                <div className={`fs-01`}>Owned by
                                    {owner ? <>
                                            <span className={`mr-025`}>{owner?.user?.username}</span> <span
                                            className={`fs-0-9 cursor-pointer hover-text`}
                                            onClick={(e) => copyToClipboard(owner?.address, e)}> ( {owner?.address.slice(0, 5)}... )</span>
                                        </>
                                        :
                                        <span className={`mr-025`}>---</span>
                                    }
                                </div>
                                <span className={`${classes.description} width-100`}>{asset?.description}</span>
                                <Button
                                    type="button"
                                    buttonClass={`${classes.thisButton} cursor-pointer mt-1`}
                                    buttonTitle={t('item.buyNow')}
                                    onClick={() => window.open(`${asset?.permalink}`)}
                                />

                            </div>
                            <div className={`column jc-between ai-start width-30 ${classes.sidebar} height-100 pr-2`}>

                                <div className={`column js-start ai-start`}>
                                    <span className={`text-gray fs-0-9`}>Birthdate at {detail?.birthdate}</span>
                                    <span className={`text-gray fs-0-9`}>Made in {detail?.country}</span>
                                </div>

                                <div className={`column jc-start ai-start`}>
                                    <div className={`row jc-center ai-center  text-gray`}>
                                        <Icon
                                            iconName="icon-link"
                                            iconClass={`ml-025`}
                                        />
                                        <span className={``}>Links</span>
                                    </div>
                                    <Link to={`${asset?.permalink}`} target="_blank"
                                          className={`row jc-start ai-center hover-text fs-0-9`}>
                                        <img src={images.openSea} alt="" className={`${classes.icon} ml-025`}/>
                                        <span className={`mr-025`}>OpenSea</span>
                                    </Link>

                                </div>
                                <div className={`column jc-start ai-start`}>
                                    <div className={`row jc-center ai-center  text-gray mb-05`}>
                                        <Icon
                                            iconName="icon-pencil"
                                            iconClass={`ml-025`}
                                        />
                                        <span className={``}>Designer</span>
                                    </div>
                                    <DesignerMiniCard data={detail?.designer}/>


                                </div>

                            </div>

                        </div>
                    </div>
                    <div className={`width-100 row jc-between ai-start mt-4`}>
                        <ModelSection name={asset?.name} animation_url={asset?.animation_original_url}/>
                    </div>

                    <div className={`width-100 row jc-between ai-start mt-4`}>
                        <Material material={detail?.material}/>
                    </div>
                </div>
        </div>
    );
};

export default Asset;
