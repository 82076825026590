import React, {useRef, useState} from 'react';
import classes from '../UploadFile/UploadFile.module.css'
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../../Hooks/useAuth";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {uploadMedia} from "../../../../../../api/auth";
import * as RoutesName from "../../../../../../Routes/routes";
import {images} from "../../../../../../assets/images/images";
import Icon from "../../../../../../components/Icon/Icon";
import Button from "../../../../../../components/Button/Button";
import ImageInput from "../../../../../../components/ImageInput/ImageInput";
import ModelViewer from "../../../../../../components/ModelViewer/ModelViewer";

const Upload3DModel = () => {

    const {t} = useTranslation();
    const {auth, setAuth} = useAuth();
    const navigate = useNavigate()

    const [status, setStatus] = useState("");
    const cid = useRef("")

    const IPFSURL = window.env.REACT_APP_IPFS_URL;

    const [media, setMedia] = useState({
        value: "", error: []
    });

    const uploadFileFunc = async () => {
        if (media.value.length <= 0) {
            return toast.error("Upload file!")
        }
        setStatus("UPLOADING")
        await uploadMedia(media?.value , auth.token)
            .then(async (res) => {


                cid.current = res?.data?.data?.value?.cid

                setAuth({...auth,
                    animationIpfsLink: `${IPFSURL}/ipfs/${res?.data?.data?.value?.cid}`,
                })
                setStatus("UPLOADED")
            })
            .catch((err) => {
                toast.error("Failed to upload file, try again!");
                console.log("err" , err)
                setStatus("")
            })
            .finally(() => {

            });

    }

    const copyToClipboard = (value, e) => {
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success("Copied");
    }

    const back = async () => {
        setStatus("")
        setMedia({
            value: "", error: []
        })
        navigate(RoutesName.CreateItem)
    }


    const content = () => {
        if (status === "UPLOADING") return <>
            <img src={images.dorsaLoading} alt="loading..." className={`mb-1`} style={{width:"35%"}}/>
            <span className={`mt-1 mb-3 fs-02`}>Uploading...</span>
        </>
        if (status === "UPLOADED") return <>
            <span className={`fs-02 font-weight-bold text-green`}>The upload is complete</span>
            <ModelViewer alt={`model`} src={`${IPFSURL}/ipfs/${cid?.current}`}/>
            <div className={`row jc-between ai-center width-100`}>
                <div className={`row jc-start ai-center width-50`}>
                    <Icon
                        iconName="icon-copy-link-icon fs-04 hover-text"
                        iconClass={`cursor-pointer`}
                        onClick={(e)=>copyToClipboard(cid?.current, e)}
                    />
                    <span className={`font-weight-bold mx-05`}>Cid:</span>
                    <span>{cid?.current.slice(0,30)}...</span>
                </div>
                <div className={`row jc-end ai-center width-50`}>
                    <Icon
                        iconName="icon-copy-link-icon fs-04 hover-text"
                        iconClass={`cursor-pointer`}
                        onClick={(e)=>copyToClipboard(`${IPFSURL}/ipfs/${cid?.current}`, e)}
                    />
                    <span className={`font-weight-bold mx-05`}>IPFS Link:</span>
                    <span className={`cursor-pointer hover-text`} onClick={()=>window.open(`${IPFSURL}/ipfs/${cid?.current}`)}>{`${IPFSURL}/ipfs/${cid?.current.slice(0,15)}`}...</span>
                </div>
            </div>
            <Button
                type="button"
                buttonClass={`${classes.thisButton} cursor-pointer mt-2`}
                buttonTitle="Done"
                onClick={()=>back()}
            />
        </>
        return <>


            <div className={`column width-100`}>
                <span className={`font-weight-bold fs-01`}>{t('items.media')}</span>
                <span className={`${classes.subLabel} fs-0-9 text-gray mb-5`}>{t('items.mediaSublabel')}</span>

                {media.value.length <= 0 ? <div className={`column width-100`}>
                        <ImageInput
                            zoneCustomClass={classes.zoneBox}
                            title={<img src={images.media} alt="" className={`${classes.zoneLogo}`}/>}
                            onchange={(url) => setMedia({...media, value: url, error: []})}
                            is3D={true}
                        />
                    </div> :
                    /*<ModelViewer alt={`model`} src={"https://ipfs.io/ipfs/bafybeid3pcof3ekxjczslxhofgkfmxzhmuyqs6t6jyxevk7fwlnx2zp4ta"}/>*/
                    <span className={`position-relative`}>
                        <Icon
                            iconName="icon-cancel-2 fs-04"
                            iconClass={`position-absolute ${classes.cancel} cursor-pointer`}
                            onClick={(e) => setMedia({value: "" , error: []})}
                        />
                        <ModelViewer alt={`model`} src={media?.value?.preview}/>
                    </span>
                }

            </div>


            <div className={`row jc-center ai-center width-100 `}>
                <Button
                    type="button"
                    buttonClass={`${classes.thisButton} cursor-pointer ml-1`}
                    buttonTitle={t('items.upload')}
                    onClick={()=>uploadFileFunc()}
                />
                <Button
                    type="button"
                    buttonClass={`${classes.thisButtonBack} cursor-pointer mr-1`}
                    buttonTitle={t('items.back')}
                    onClick={()=>navigate(RoutesName.CreateItem)}
                />
            </div>

        </>
    }



    return (
        <div className={`${classes.content} width-70 px-5 py-3 rounded-8 column jc-between ai-center`}>
            {content()}
        </div>
    );
};

export default Upload3DModel;
