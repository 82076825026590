import React from 'react';
import classes from './RingMaterial.module.css'

const RingMaterial = ({data}) => {
    console.log("data" , data)


    let radius = 130;
    let stroke = 7;
    let progress = 100 - data;

    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    const strokeDashoffset = circumference - progress / 100 * circumference;

    return (
            <svg
                height={radius * 2}
                width={radius * 2}
            >
                <circle
                    stroke="#EFBE0F"
                    fill="#f7f7f7"
                    strokeWidth={ stroke }
                    strokeDasharray={ circumference + ' ' + circumference }
                    style={ { strokeDashoffset } }
                    stroke-width={ stroke }
                    r={ normalizedRadius }
                    cx={ radius }
                    cy={ radius }
                />
                <text x="50%" y="40%" dominant-baseline="middle" text-anchor="middle" className={`font-weight-bold fs-03`}>{progress} %</text>
                <text x="50%" y="60%" dominant-baseline="middle" text-anchor="middle">Material</text>
            </svg>
    );
};

export default RingMaterial;
