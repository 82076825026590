import React from 'react';
import classes from './CollectionInfo.module.css'


const CollectionInfo = ({collection}) => {

    return (
        <div className={`px-7 row jc-start ai-center my-3 wrap ${classes.container}`}>
            <div className={`column jc-center ai-center width-32 my-1`}>
                <span className={`font-weight-bold fs-03`}>{collection?.stats?.floor_price ? <span>{collection?.stats?.floor_price} <span className={`fs-0-8`}>ETH</span> </span> : "---"}</span>
                <span className={`${classes.divider} width-80 my-1`}></span>
                <span>Floor Price</span>
            </div>
            <div className={`column jc-center ai-center width-32 my-1`}>
                <span className={`font-weight-bold fs-03`}>{collection?.stats?.total_volume ? collection?.stats?.total_volume : "---"} </span>
                <span className={`${classes.divider} width-80 my-1`}></span>
                <span>Volume</span>
            </div>
            <div className={`column jc-center ai-center width-32 my-1`}>
                <span className={`font-weight-bold fs-03`}>{collection?.stats?.average_price ? <span>{collection?.stats?.average_price} <span className={`fs-0-8`}>ETH</span> </span> : "---"}</span>
                <span className={`${classes.divider} width-80 my-1`}></span>
                <span>Average Price</span>
            </div>
            <div className={`column jc-center ai-center width-32 my-1`}>
                <span className={`font-weight-bold fs-03`}>{collection?.stats?.count ? collection?.stats?.count : "---"} </span>
                <span className={`${classes.divider} width-80 my-1`}></span>
                <span>Items</span>
            </div>
            <div className={`column jc-center ai-center width-32 my-1`}>
                <span className={`font-weight-bold fs-03`}>{collection?.stats?.num_owners ? collection?.stats?.num_owners : "---"} </span>
                <span className={`${classes.divider} width-80 my-1`}></span>
                <span>Owners</span>
            </div>
        </div>
    );
};

export default CollectionInfo;
