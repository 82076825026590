import React, {useEffect} from 'react';
import classes from './Collection.module.css'
import ScrollBar from "../../../../components/ScrollBar";
import {images} from "../../../../assets/images/images";
import {Link, useParams} from "react-router-dom";
import Icon from "../../../../components/Icon/Icon";
import CollectionInfo from "./Module/CollectionInfo/CollectionInfo";
import {useGetCollection} from "../../../../query";
import moment from "moment-jalaali";
import Assets from "../Assets/Assets";
import Loading from "../../../../components/Loading/Loading";

const Collection = () => {

    const {name,address} = useParams()

    const exploreURL = window.env.REACT_APP_BLOCK_EXPLORER;
    const openSeaURL = window.env.REACT_APP_OPENSEA_URL;

    const {data: collection, isLoading, error} = useGetCollection(address)

    if (isLoading) return <div className={`width-100 column jc-center ai-center min-height-100`}>
        <Loading/>
        <span className={`mt-5`}>Loading...</span>
    </div>

    return (
        <div className={`${classes.container} min-height-100`}>
                <div className={`${classes.header} position-relative`}
                     style={{backgroundImage: `url("${collection?.banner_image_url?.replace("w=500", "w=1920")}")`}}
                >
                    <div className={`${classes.avatar} position-absolute flex jc-center ai-center`}
                         style={{backgroundImage: `url("${collection?.image_url}")`}}
                    >
                        {/*<img src={collection?.image_url} alt=""/>*/}
                    </div>
                </div>

                <div className={`width-80 row jc-end ai-center m-auto`}>
                    {collection?.opensea && <Link to={`${openSeaURL}/collection/${collection?.opensea?.collection?.slug}`} target="_blank"
                          className={`row jc-end ai-center mt-2 hover-text`}>
                        <img src={images.openSea} alt="" className={`${classes.icon}`}/>
                        <span className={`mr-025`}>OpenSea</span>
                    </Link>}
                    <span className={`row jc-center ai-center mt-2 mx-2`}>
                        <Icon iconName="icon-schedule-calendar-icon-1 fs-05" iconClass={`cursor-pointer`}/>
                        <span className={`mr-025`}>Created {moment(collection?.created_date).format("MMM YYYY")}</span>
                    </span>
                    <Link
                        to={`${exploreURL}/address/${address}`}
                        target="_blank" className={`row jc-center ai-center mt-2 hover-text`}>
                        <Icon
                            iconName="icon-globe-network-icon-1 fs-05"
                            iconClass={`cursor-pointer`}
                        />
                        <span className={`mr-025`}>View on network</span>
                    </Link>
                </div>

                <div className={`${classes.content} width-80 m-auto column`}>
                    <span className={`font-weight-bold fs-09`}>{collection?.name}</span>
                    <span className={`fs-03 mt-2`}>{collection?.description}</span>
                </div>

                <div className={`my-7`}/>

                <CollectionInfo collection={collection?.opensea?.collection}/>

                <div className={`my-7`}/>

                <Assets creator={collection?.creator}/>

                <div className={`my-7`}/>
        </div>
    );
};

export default Collection;
