import React from 'react';
import classes from './Layout.module.css'
import ScrollBar from "../../../components/ScrollBar";
import {Outlet} from "react-router-dom";
import Header from "./Header/Header";

const Layout = () => {


    return (
        <>
            <div className={`${classes.container} column`}>
                <Header/>
                <div className={`${classes.content}`}>
                    <ScrollBar>
                        <div style={{minHeight: '89.5vh'}}>
                            <Outlet/>
                        </div>
                        <div className={`${classes.footer} flex jc-center ai-center`}>
                            <span className={`cursor-pointer hover-text`} onClick={()=>window.open("https://nilin.co")}>Designed and developed by Nilin</span>
                        </div>
                    </ScrollBar>
                </div>
            </div>
        </>
    );
};

export default Layout;
