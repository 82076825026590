import React from 'react';
import classes from './ModelSection.module.css'
import Title from "../../../../../../components/Title/Title";
import ModelViewer from "../../../../../../components/ModelViewer/ModelViewer";
import {getIpfsUrl} from "../../../../../../utils";

const ModelSection = ({name , animation_url}) => {
    return (
        <div className={`column width-100 my-4`}>
            <span className={`mb-3`}><Title title="3D Model"/></span>
            <ModelViewer alt={`${name} model`} src={getIpfsUrl(animation_url)}/>
        </div>
    );
};

export default ModelSection;
