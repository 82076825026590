import React from 'react';
import {Route, Routes} from "react-router-dom";
import * as RoutesName from "../../../../Routes/routes";
import UploadFile from "./Module/UploadFile/UploadFile";
import AddItem from "./Module/AddItem/AddItem";


const CreateItem = () => {
    return (

    <Routes>
        <Route path="/" element={<AddItem/>}>
            <Route path={RoutesName.UploadFileRelative + "/:type"} element={<UploadFile/>}/>
        </Route>
    </Routes>
    );
};

export default CreateItem;
