import {useQuery} from "@tanstack/react-query";
import axios from "../../api/axios";

export const useGetCollectionByOwnerAddress = () => {
    return useQuery(
        ['collections'],
        async () => {
            const {data} = await axios.get(`/api/v1/collections`)
            return data?.data;
        },
    );
}