import React from 'react';
import classes from './Header.module.css'
import {images} from "../../../../assets/images/images";
import Icon from "../../../../components/Icon/Icon";

import * as RoutesName from "../../../../Routes/routes";
import {Link, NavLink} from "react-router-dom";

const Header = ({setActive}) => {
    return (
        <div className={`${classes.header} row jc-between ai-center px-7`}>

            <Link to={RoutesName.Home} className={`row jc-center ai-center`}>
                {/*<img src={images.dorsaLogo} alt="" className={`${classes.logo} rounded-4 ml-2`}/>*/}
                <span className={`font-weight-bold fs-04`}>NlN NFT</span>
            </Link>

            <div className={`height-100 row ai-center fs-01 jc-end`}>
                <NavLink to={RoutesName.Home} className={({isActive}) => isActive ? `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative ${classes.selected}`: `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative`}>
                    Home
                    <span className={`width-100 position-absolute`}/>
                </NavLink>
                <NavLink to={RoutesName.Collections} className={({isActive}) => isActive ? `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative ${classes.selected}`: `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative`}>
                    Collections
                    <span className={`width-100 position-absolute`}/>
                </NavLink>
                <NavLink to={RoutesName.Designers} className={({isActive}) => isActive ? `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative ${classes.selected}`: `cursor-pointer hover-text mr-3 height-100 row jc-center ai-center position-relative`}>
                    Designers
                    <span className={`width-100 position-absolute`}/>
                </NavLink>



            </div>



        </div>
    );
};

export default Header;
