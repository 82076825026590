import React from 'react';
import classes from './DesignersCard.module.css'
import useAuth from "../../../../../../Hooks/useAuth";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";
import moment from "moment-jalaali";
import Icon from "../../../../../../components/Icon/Icon";

const DesignersCard = ({data, id, multi}) => {
    const {auth} = useAuth();
    const navigate = useNavigate()

    //console.log("data designerData" , designerData)

    const fullname = `${data?.firstname}-${data?.lastname}`;

    const navigateHandler = () => {

        if (multi) return navigate(RoutesName.ShowDesigner(data?.network_id, fullname))
        return false;

    }

    return (
        <div className={`${classes.container} row ai-start width-100 rounded-8 position-relative mt-3 mb-5 px-2 py-1`} onClick={() => navigateHandler()}>
            <div className={`${classes.avatar} ${data?.hide && classes.activeHide} position-absolute`}
                 style={{backgroundImage: `url("${data?.imageLink}")`}}
            >
            </div>
            {data?.hide && <div className={`${classes.badge} rounded-8 width-10 flex jc-center ai-center position-absolute`}>
                Hidden
            </div>}

            <div className={`width-40 height-100 column jc-start ai-center ${classes.title} pb-05 ${data?.hide && "gray-filter"}`}>

                <span className={`font-weight-bold fs-02`}>{data?.firstname}  {data?.lastname}</span>
                {/*<span className={`text-gray mt-025`}>ID: {id}</span>*/}
                <span className={`text-gray`}>{data?.title}</span>

            </div>

            <div className={`width-60 column pr-1`}>
                <div className={`column jc-between ai-center`}>
                    <div className={`row jc-between ai-center width-100`}>
                        <div className={`row ${data?.hide && "gray-filter"}`}>{data?.email}</div>
                        { multi &&<Icon
                            iconName=" icon-right-open fs-02"
                            iconClass={`cursor-pointer hover-text mr-1`}
                            onClick={() => navigateHandler()}
                        />}
                    </div>
                    <div className={`row jc-between ai-center width-100`}>
                        <div className={`row ${data?.hide && "gray-filter"}`}>From {data?.nationality}</div>
                        <div className={`row ${data?.hide && "gray-filter"}`}>Born in {moment(data?.birthdate).format("MMM YYYY")}</div>
                    </div>
                </div>

                <span className={`${classes.divider} width-100 my-1`}></span>


                <div className={` ${data?.hide && "gray-filter"}`}>

                    {
                        multi ? `${data?.description.slice(0,100)}...` : data?.description
                    }
                </div>

            </div>


        </div>
    );
};

export default DesignersCard;
