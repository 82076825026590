import React, {useEffect} from 'react';
import classes from './CollectionInfo.module.css'
import {useGetCollection} from "../../../../../../query";
import {useParams} from "react-router-dom";


const CollectionInfo = ({collection}) => {
    return (
            <div className={`width-80 m-auto row jc-between ai-center`}>
                <div className={`${classes.box} width-17 column jc-center ai-center py-5 rounded-8`}>
                    <span className={`font-weight-bold fs-03`}>{collection?.stats?.floor_price ? <span>{collection?.stats?.floor_price} <span className={`fs-0-8`}>ETH</span> </span> : "---"}</span>
                    <span className={`${classes.divider} width-80 my-3`}></span>
                    <span>Floor Price</span>
                </div>
                <div className={`${classes.box} width-17 column jc-center ai-center py-5 rounded-8`}>
                    <span className={`font-weight-bold fs-03`}>{collection?.stats?.total_volume ? collection?.stats?.total_volume : "---"} </span>
                    <span className={`${classes.divider} width-80 my-3`}></span>
                    <span>Volume</span>
                </div>
                <div className={`${classes.box} width-17 column jc-center ai-center py-5 rounded-8`}>
                    <span className={`font-weight-bold fs-03`}>{collection?.stats?.average_price ? <span>{collection?.stats?.average_price} <span className={`fs-0-8`}>ETH</span> </span> : "---"}</span>
                    <span className={`${classes.divider} width-80 my-3`}></span>
                    <span>Average Price</span>
                </div>
                <div className={`${classes.box} width-17 column jc-center ai-center py-5 rounded-8`}>
                    <span className={`font-weight-bold fs-03`}>{collection?.stats?.count ? collection?.stats?.count : "---"} </span>
                    <span className={`${classes.divider} width-80 my-3`}></span>
                    <span>Items</span>
                </div>
                <div className={`${classes.box} width-17 column jc-center ai-center py-5 rounded-8`}>
                    <span className={`font-weight-bold fs-03`}>{collection?.stats?.num_owners ? collection?.stats?.num_owners : "---"} </span>
                    <span className={`${classes.divider} width-80 my-3`}></span>
                    <span>Owners</span>
                </div>
            </div>
    );
};

export default CollectionInfo;
