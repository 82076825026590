import React, {useEffect, useState} from 'react';
import classes from './Designers.module.css';
import DesignersCard from "./Module/DesignersCard/DesignersCard";
import Title from "../../../../components/Title/Title";
import * as RoutesName from "../../../../Routes/routes";
import Icon from "../../../../components/Icon/Icon";
import {useNavigate} from "react-router-dom";
import useAuth from "../../../../Hooks/useAuth";
import {getAllDesignersFunc, onlyActive} from "../../../../utils";
import Loading from "../../../../components/Loading/Loading";
import {useGetDesigners, useGetOwners} from "../../../../query";

const Designers = () => {

    const navigate = useNavigate();
    const {auth} = useAuth();

    const {data, isLoading, error} = useGetDesigners()

    const content = () => {
        if (error) return  <span className={`my-10`}>Failed to get designers</span>
        if (isLoading) return  <span className={`my-10`}><Loading/></span>
        if (data?.length === 0) return  <span className={`my-10`}>No Designer!</span>
        return data?.map((d , index )=> <DesignersCard data={d} /*id={list?.id}*/ key={d?.id} multi={true}/>)
    }

    return (
        <div className={`width-80 m-auto py-5 column jc-start ai-center min-height-100`}>

            {auth?.isLogin &&
                <div className={`row mt-3 mb-7 width-100 ${classes.CreateDesigner} cursor-pointer hover-text rounded-8 py-2 px-1`}
                     onClick={() => navigate(RoutesName.CreateDesigner)}
                >
                    <Icon
                        iconName="icon-plus-circle fs-20"
                        iconClass={`cursor-pointer ml-025`}
                    />
                    <div className={`fs-01`}>Add New Designer</div>
                </div>

            }

            <span className={`mb-5 width-100`}><Title title="Designers"/></span>
            {content()}



        </div>
    );
};

export default Designers;
