import React, {useRef, useState} from 'react';
import classes from '../../UploadFile.module.css'
import {images} from "../../../../../../assets/images/images";
import Icon from "../../../../../../components/Icon/Icon";
import Button from "../../../../../../components/Button/Button";
import ImageInput from "../../../../../../components/ImageInput/ImageInput";
import * as RoutesName from "../../../../../../Routes/routes";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useAuth from "../../../../../../Hooks/useAuth";
import toast from "react-hot-toast";
import {uploadMedia} from "../../../../../../api/auth";

const UploadImage = ({name, setOpenUploadFile}) => {

    const {t} = useTranslation();
    const {auth, setAuth} = useAuth();
    const navigate = useNavigate()

    const location = useLocation();

    /*const {name} = useParams();*/

    const from = location.state?.from?.pathname || "/";
    console.log("from" , from)


    const [status, setStatus] = useState("");
    const cid = useRef("")

    const IPFSURL = window.env.REACT_APP_IPFS_URL;

    const [media, setMedia] = useState({
        value: "", error: []
    });


    const uploadFileFunc = async () => {
        if (media.value.length <= 0) {
            return toast.error("Upload file!")
        }
        setStatus("UPLOADING")
        await uploadMedia(media?.value , auth.token)
            .then(async (res) => {
                const newAuth = {...auth}
                cid.current = res?.data?.data?.value?.cid
                newAuth[name + "IpfsLink"] = `${IPFSURL}/ipfs/${res?.data?.data?.value?.cid}`
                /*setAuth({...auth,
                    name: `${IPFSURL}/ipfs/${res?.data?.data?.value?.cid}`,
                })*/
                setStatus("UPLOADED")
                setAuth(newAuth)
            })
            .catch((err) => {
                toast.error("Failed to upload file, try again!");
                console.log("err" , err)
                setStatus("")
            })
            .finally(() => {

            });



    }

    const copyToClipboard = (value, e) => {
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success("Copied");
    }

    const back = async () => {
        setStatus("")
        setMedia({
            value: "", error: []
        })
        setOpenUploadFile(false)
        //navigate(from)
    }

    const content = () => {
        if (status === "UPLOADING") return <>
            <img src={images.dorsaLoading} alt="loading..." className={`mb-1`} style={{width:"70%"}}/>
            <span className={`mt-1 fs-02`}>Uploading...</span>
        </>
        if (status === "UPLOADED") return <>
            <span className={`fs-02 font-weight-bold text-green`}>The upload is complete</span>
            <div className={`${classes.imageBox} my-1 width-70`}
                 style={{backgroundImage: `url("${IPFSURL}/ipfs/${cid?.current}")`}}
            >

            </div>
            {/*<img src={`${IPFSURL}/ipfs/${cid?.current}`} alt="" className={`my-3`} style={{width:"60%"}}/>*/}
            <div className={`column width-100`}>
                <div className={`row jc-start ai-center width-90 mb-1`}>
                    <Icon
                        iconName="icon-copy-link-icon fs-04 hover-text"
                        iconClass={`cursor-pointer`}
                        onClick={(e)=>copyToClipboard(cid?.current, e)}
                    />
                    <span className={`font-weight-bold mx-05`}>Cid:</span>
                    <span>{cid?.current.slice(0,30)}...</span>
                </div>
                <div className={`row jc-start ai-center width-90 mt-1`}>
                    <Icon
                        iconName="icon-copy-link-icon fs-04 hover-text"
                        iconClass={`cursor-pointer`}
                        onClick={(e)=>copyToClipboard(`${IPFSURL}/ipfs/${cid?.current}`, e)}
                    />
                    <span className={`font-weight-bold mx-05`}>IPFS Link:</span>
                    <span className={`cursor-pointer hover-text`} onClick={()=>window.open(`${IPFSURL}/ipfs/${cid?.current}`)}>{`${IPFSURL}/ipfs/${cid?.current.slice(0,15)}`}...</span>
                </div>
            </div>
            <Button
                type="button"
                buttonClass={`${classes.thisButton} cursor-pointer mt-2`}
                buttonTitle="Done"
                onClick={()=>back()}
            />
        </>
        return <>
            <div className={`column width-100`}>
                <span className={`font-weight-bold fs-01`}>{t('items.image')}</span>
                <span className={`${classes.subLabel} fs-0-9 text-gray mb-5`}>{t('items.imageSublabel')}</span>
                <ImageInput
                    zoneCustomClass={`${classes.zoneBox} `}
                    title={<img src={images.image} alt="" className={`${classes.zoneLogo}`}/>}
                    onchange={(url) => setMedia({...media, value: url, error: []})}
                />
            </div>
            <div className={`row jc-center ai-center width-100`}>
                <Button
                    type="button"
                    buttonClass={`${classes.thisButton} cursor-pointer ml-1`}
                    buttonTitle={t('items.upload')}
                    onClick={()=>uploadFileFunc()}
                />
                <Button
                    type="button"
                    buttonClass={`${classes.thisButtonBack} cursor-pointer mr-1`}
                    buttonTitle={t('items.back')}
                    onClick={()=>setOpenUploadFile(false)}
                />
            </div>

        </>
    }


    return (
        <div className={`${classes.content} width-35 px-5 py-3 rounded-8 column jc-between ai-center`}>
            {content()}
        </div>
    )
};

export default UploadImage;
