import React from 'react';
import classes from '../../CreateAsset.module.css'

const DesignerOption = ({data, withAvatar}) => {
    return (
        <div className={`row jc-start ai-center`}>

            { withAvatar && <div className={`${classes.avatar} ml-1`}
                 style={{backgroundImage: `url("${data?.imageLink}")`}}
            />}
            <span>{data?.firstname} {data?.lastname}</span>
        </div>
    );
};

export default DesignerOption;
