import React, {useEffect, useState} from 'react';

import {ResponsivePie} from '@nivo/pie'

const MaterialChart = ({data}) => {

    const [material, setMaterial] = useState([]);

    const createMaterialData = () => {
        const materialData = []
        for (let i = 0; i < data.length; i++) {
            materialData.push({
                id: data[i].type,
                label: data[i].type,
                value: data[i].percent,
            })
        }
        setMaterial(materialData)
    }

    useEffect(() => {
        if (data) createMaterialData()
    }, []);

    if (!data) {
        return <div className={`width-100 flex jc-center ai-center my-10`}>
            <span className={`fs-04`}>Material data not available</span>
        </div>
    }

    const baseData = [
        {
            id: "java",
            label: "java",
            value: 195,
            //color: "hsl(90, 70%, 50%)"
        },
        {
            id: "erlang",
            label: "erlang",
            value: 419,
            //color: "hsl(56, 70%, 50%)"
        },
        {
            id: "ruby",
            label: "ruby",
            value: 407,
            //color: "hsl(103, 70%, 50%)"
        },
        {
            id: "haskell",
            label: "haskell",
            value: 474,
            //color: "hsl(186, 70%, 50%)"
        },
        {
            id: "go",
            label: "go",
            value: 71,
            //color: "hsl(104, 70%, 50%)"
        }
    ];

    return (
        <>

            <div style={{height: "38vh"}} className={``}>
                <ResponsivePie
                    //colors={{ scheme: 'greys' }}
                    //colors={['#ccc', '#eee']}
                    colors={['#eeeeee', '#eeeeee']}
                    colorBy="index"
                    borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                   // borderColor={{ theme: 'background' }}

                    //colors={{ scheme: 'nivo' }}
                    //borderColor="#000000"

                    data={material}
                    margin={{ top: 40, right: 90, bottom: 40, left: 90 }}
                    innerRadius={0.50}
                    padAngle={3}
                    cornerRadius={4}
                    fill={[
                        {background: "#000"}
                    ]}
                    theme={{
                        "text": {
                            "fontSize": 30,
                            "fontFamily": 'Mulish',
                            "fill": "#333333",
                            "outlineWidth": 0,
                            "outlineColor": "transparent"
                        },

                        "legends": {
                            "title": {
                                "text": {
                                    "fontSize": 15,
                                    "fontFamily": 'Mulish',
                                    "fill": "#000",
                                    "outlineWidth": 0,
                                    "outlineColor": "transparent"
                                }
                            },
                            "text": {
                                "fontSize": 15,
                                "fill": "#000",
                                "fontFamily": 'Mulish',
                                "outlineWidth": 0,
                                "outlineColor": "transparent"
                            },
                            "ticks": {
                                "line": {},
                                "text": {
                                    "fontSize": 15,
                                    "fill": "#000",
                                    "fontFamily": 'Mulish',
                                    "outlineWidth": 0,
                                    "outlineColor": "transparent"
                                }
                            }
                        },
                        "labels": {
                            "title": {
                                "text": {
                                    "fontSize": 13,
                                    "fontFamily": 'Mulish',
                                    "fill": "#000",
                                    "outlineWidth": 2,
                                    "outlineColor": "#000",
                                }
                            },
                            "text": {
                                "fontSize": 13,
                                "fill": "#000",
                                "fontFamily": 'Mulish',
                                "outlineWidth": 2,
                                "outlineColor": "#000",
                            },
                            "ticks": {
                                "line": {},
                                "text": {
                                    "fontSize": 13,
                                    "fill": "#000",
                                    "fontFamily": 'Mulish',
                                    "outlineWidth": 20,
                                    "outlineColor": "#000",
                                }
                            }
                        },

                        "grid": {
                            "line": {
                                "stroke": "#dddddd",
                                "strokeWidth": 1,
                                "fontSize": 18,
                                "fontFamily": 'Mulish',
                            }
                        },

                    }

                }

                    activeOuterRadiusOffset={8}
                   // colors={{ scheme: 'greys' }}
                    borderWidth={1}

                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextOffset={13}
                    arcLinkLabelsStraightLength={0}
                    arcLinkLabelsDiagonalLength={7}
                    arcLinkLabelsOffset={12}
                    arcLinkLabelsThickness={3}
                    arcLinkLabelsTextColor="#000"


                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{ from: "color", modifiers: [["darker", 3]] }}
                    activeInnerRadiusOffset={15}

                    /*legends={[
                        {
                            anchor: 'bottom',
                            direction: 'row',
                            justify: true,
                            translateX: 10,
                            translateY: 80,
                            itemWidth: 91,
                            itemHeight: 20,
                            itemsSpacing: 40,
                            symbolSize: 15,
                            itemDirection: 'left-to-right'
                        }
                    ]}*/
                />
            </div>

        </>
    );
};

export default MaterialChart;
