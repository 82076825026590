import React from 'react';
import classes from './RangeTextInput.module.css'
import Icon from "../Icon/Icon";

const RangeTextInput = ({inputClass, onchange, label, value, alerts, subLabel, textarea, sideContent, ...props }) => {
    return (
       <div className={`${inputClass}`}>
           <div className={`${classes.inputGroup} column ai-start ${alerts.length !== 0 && classes.hasError}`}>
               <div className={`row jc-between ai-center width-100`}>
                   <label className={`font-weight-bold fs-01`}>{label}</label>
                   {sideContent && sideContent}
               </div>
               {subLabel && <span className={`${classes.subLabel} fs-0-9 text-gray mb-2`}>{subLabel}</span>}

                   <div className={`position-relative width-100`}>
                       <input
                           onChange={onchange}
                           value={value}
                           {...props}
                           type="number"
                           className={`px-1`}
                       />
                       <input
                           onChange={onchange}
                           value={value}
                           type="range"
                           {...props}
                       />
                   </div>

               {alerts?.length !== 0 && <Icon iconName="icon-info-circled flex fs-05 text-blue" iconClass={`${classes.thisIcon}`}/>}
           </div>
           {alerts && <div className={`column fs-0-9 text-blue mt-025`}>
               {alerts.map((alert, index) => <span key={index} className={``}>{alert}</span>)}
           </div>}
       </div>


    );
};

export default RangeTextInput;
