import React from 'react';
import AccountsList from "./Module/AccountsList/AccountsList";

const Accounts = () => {
    return (
        <div className={`width-80 m-auto py-5 column min-height-100`}>
            <div className={`column jc-start`}>
                <div className={`width-100 column jc-center ai-center`}>
                    <span className={`font-weight-bold fs-05 mb-05`}>Accounts</span>
                    <span className={`text-gray mt-05 mb-1`}>Verified Accounts to display collections and designers</span>
                </div>
                <AccountsList/>
            </div>
        </div>
    );
};

export default Accounts;
