import React, {useEffect, useState} from 'react';
import classes from './MetamaskConnection.module.css'
import {connectToMetamask} from "../../../../../../utils";
import Button from "../../../../../../components/Button/Button";
import {useTranslation} from "react-i18next";
import Loading from "../../../../../../components/Loading/Loading";
import {images} from "../../../../../../assets/images/images";
import toast from "react-hot-toast";
import useAuth from "../../../../../../Hooks/useAuth";
import {getCurrentWalletAddress} from "../../../../../../web3/web3Utils";

const MetamaskConnection = () => {

    const {t} = useTranslation();
    const {auth, setAuth} = useAuth();

    const [connect, setConnect] = useState(false);
    const [loading, setLoading] = useState(false);

    const isAccountChanged = (accounts) => {
        if (accounts.length === 0) {
            setConnect(false)
            setAuth({
                ...auth,
                isConnected: false,
                account: null,
            })
        } else {
            setConnect(true)
            setAuth({
                ...auth,
                isConnected: true,
                account: accounts[0],
            })
        }
    }


    useEffect(() => {
        const walletAddress = getCurrentWalletAddress();
        if (walletAddress) {
            setConnect(true)
            window.ethereum.on('accountsChanged', isAccountChanged);
            setAuth({
                ...auth,
                isConnected: true,
                account: walletAddress,
            })
        }
    }, []);


    const connectFunc = async () => {
        setLoading(true)
        await connectToMetamask(isAccountChanged)
        setLoading(false)
    }

    const copyToClipboard = (value, e) => {
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success("Copied");
    }

    if (loading) return <span className={`flashit`}>Connecting...</span>

    if (!connect) return <Button
        type="button"
        buttonClass={`${classes.thisButtonMeta} cursor-pointer px-1`}
        buttonTitle={t('items.connect')}
        onClick={() => connectFunc()}
    />

    return (
        <div className={`row jc-end`}>
            <img src={images.metamask} alt="" className={`${classes.metaIcon} ml-025`}/>
            <span className={`mr-025 cursor-copy hover-text`}
                  onClick={(e) => copyToClipboard(auth.account, e)}>( {auth.account.slice(0, 8)}... )</span>
        </div>
    );
};

export default MetamaskConnection;
