import React from 'react';
import classes from './DesignerAssets.module.css'
import {useGetAssetByDesigner, useGetCollectionAssets} from "../../../../../../query";
import Loading from "../../../../../../components/Loading/Loading";
import AssetsCard from "../../../Assets/Module/AssetsCard/AssetsCard";
import Title from "../../../../../../components/Title/Title";

const DesignerAssets = ({designer, designerName}) => {


    console.log("designer", designer)

    const {data , isLoading , error} = useGetAssetByDesigner(designer)

    console.log("data" ,data)

    const content = () => {
        if (error) return <div className={`width-100 flex jc-center ai-center my-10`}>
            <span className={`fs-04`}>Failed to get assets!</span>
        </div>

        if (isLoading) return <div className={`width-100 column jc-center ai-center my-10`}>
            <Loading/>
            <span className={`mt-5`}>Loading...</span>
        </div>

        if (data.length === 0 ) return <div className={`width-100 column jc-center ai-center my-10`}>
            <span className={`mt-5`}>No Asset!</span>
        </div>

        return data?.map((asset , index) => <AssetsCard data={asset} index={index} key={asset?.token_id}/>)

    }


    return (
        <div className={`width-100 `}>

            <div className={`row jc-between ai-center mb-3 mt-4`}>
                <Title title={`NFTs designed by ${designerName}`}/>
                <span className={`fs-02`}>{data?.length}</span>
            </div>
            <div className={`width-100 row jc-start wrap`}>
                {content()}
            </div>

        </div>
    );
};

export default DesignerAssets;
