import {createContext, useEffect, useState} from "react";

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({
        isLogin: false,
        token: null,
        display_name: null,
        imageIpfsLink: "",
        animationIpfsLink: "",
        isConnected: false,
        account: null,
    });

    useEffect(() => {
        // storing input name
        if (auth.token) {
            localStorage.setItem("token", auth.token);
        }
    }, [auth]);

    return (
        <AuthContext.Provider value={{auth, setAuth}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;