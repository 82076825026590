import React from 'react';
import classes from './DesignerMiniCard.module.css'
import {useGetDesigner} from "../../../../../../query";
import {Link} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";
import Loading from "../../../../../../components/Loading/Loading";

const DesignerMiniCard = ({data}) => {

    const id = data?.slice(0, data.indexOf("-"))

    const {data:designer, isLoading, error} = useGetDesigner(id)


    const fullname = `${designer?.firstname}-${designer?.lastname}`;


    /*return <span className={`my-1`}><Loading type="linear"/></span>*/
    if (isLoading) return <span className={`text-gray`}>Getting data...</span>
    if (error?.response?.status === 403) return <span>Access Denied!</span>

    return (

        <Link to={RoutesName.ShowDesigner(designer?.network_id, fullname)} className={`row jc-start ai-center`}>
            <div
                className={`${classes.avatar} ml-05`}
                 style={{backgroundImage: `url("${designer?.imageLink}")`}}
            />
            <span>{designer?.firstname} {designer?.lastname}</span>
        </Link>
    );
};

export default DesignerMiniCard;
