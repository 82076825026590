import React from 'react';
import classes from './Dashboard.module.css'
import {Link} from "react-router-dom";
import * as RoutesName from "../../../../Routes/routes";
import useAuth from "../../../../Hooks/useAuth";
import {Accounts} from "../../../../Routes/routes";

const Dashboard = () => {
    const {auth} = useAuth();
    return (
        <div className={`width-80 m-auto column jc-start ai-center py-5 min-height-100`}>
            <div className={`width-100 column jc-center ai-center my-5`}>
                <span className={`font-weight-bold fs-20`}>Manage your NFT</span>
                <div className={`column jc-center ai-center mt-3 width-100`}>
                    <div className={`row mb-025`}>
                        <span className={`ml-025`}>User: </span>
                        <span className={`mr-025`}>{auth.display_name}</span>
                    </div>
                    <div className={` text-gray`}>
                        <span>{auth?.isConnected ? auth?.account : `Wallet not connected!`}</span>
                    </div>
                </div>
                <span className={`fs-01 mt-2`}></span>
            </div>

            <div className={`row jc-center ai-center width-100`}>
                <Link to={RoutesName.Collections}
                      className={`${classes.card} width-19 mx-1 flex jc-center ai-center fs-03 rounded-8 cursor-pointer hover-text`}>Collections</Link>
                <Link to={RoutesName.Designers}
                      className={`${classes.card} width-19 mx-1 flex jc-center ai-center fs-03 rounded-8 cursor-pointer hover-text`}>Designers</Link>
                <Link to={RoutesName.Accounts}
                      className={`${classes.card} width-19 mx-1 flex jc-center ai-center fs-03 rounded-8 cursor-pointer hover-text`}>Accounts</Link>
                <Link to={RoutesName.Material}
                      className={`${classes.card} width-19 mx-1 flex jc-center ai-center fs-03 rounded-8 cursor-pointer hover-text`}>Material</Link>

            </div>
        </div>
    );
};

export default Dashboard;
