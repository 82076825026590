import React from 'react';
import classes from './Material.module.css'
import MATERIAL_TYPE from "../../../../lang/materialType";
import MaterialBox from "./Module/MaterialBox/MaterialBox";
import Title from "../../../../components/Title/Title";

const Material = () => {
    return (
        <div className={`${classes.container} width-80 m-auto py-5 column jc-start ai-center min-height-100`}>


            <div className={`mb-4 width-100`}><Title title="Materials List"/></div>

            <div className={`width-100 row jc-start wrap`}>

                {MATERIAL_TYPE.map((mat)=><MaterialBox data={mat}/>)}
            </div>


        </div>
    );
};

export default Material;