import React from 'react';
import {useGetContractAddresses, useGetOwners} from "../../../../../../query";
import AccountCard from "../AccountCard/AccountCard";
import Title from "../../../../../../components/Title/Title";
import Loading from "../../../../../../components/Loading/Loading";

const AccountsList = () => {

    const {data, isLoading, error} = useGetOwners()


    if (error) return <div className={`width-100 flex jc-center ai-center my-10`}>
        <span className={`fs-04`}>Failed to get Collections!</span>
    </div>

    if (isLoading) return <div className={`width-100 column jc-center ai-center my-10`}>
        <Loading/>
        <span className={`mt-5`}>Loading...</span>
    </div>

    return (
        <div className={`width-100 mt-2`}>
            <div className={`mb-4`}><Title title="Accounts List"/></div>
            {data?.map((account , index) => <AccountCard data={account} index={index} key={index}/>)}
        </div>
    );
};

export default AccountsList;
