import React, {useRef, useState} from 'react';
import classes from './UploadFile.module.css'
import {useNavigate, useParams} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";
import ImageInput from "../../../../../../components/ImageInput/ImageInput";
import {images} from "../../../../../../assets/images/images";
import {useTranslation} from "react-i18next";
import Button from "../../../../../../components/Button/Button";
import toast from "react-hot-toast";
import {uploadMedia} from "../../../../../../api/auth";
import useAuth from "../../../../../../Hooks/useAuth";
import Icon from "../../../../../../components/Icon/Icon";
import UploadImage from "../UploadImage/UploadImage";
import Upload3DModel from "../Upload3DModel/Upload3DModel";


const UploadFile = () => {


    const {type} = useParams();


    const content = () => {

        if (type === "image") return <UploadImage/>
        if (type === "3d-model") return <Upload3DModel/>

    }



    return (
        <div className={`${classes.container} column jc-between ai-center py-10`}>
          <div className={`${classes.header} row jc-center ai-center text-white`}>
              <span className={`font-weight-bold fs-20`}>Upload Media <span className={`fs-01 mr-05`}> To IPFS</span></span>
          </div>
          {content()}
        </div>
    );
};

export default UploadFile;
