import React, {useEffect, useState, useRef} from 'react';
import classes from './CreateCollection.module.css'
import Title from "../../../../../../components/Title/Title";
import {Trans, useTranslation} from "react-i18next";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import useAuth from "../../../../../../Hooks/useAuth";
import TextInput from "../../../../../../components/TextInput/TextInput";
import Icon from "../../../../../../components/Icon/Icon";
import * as Routes from "../../../../../../Routes/routes";
import Button from "../../../../../../components/Button/Button";
import {getCollectionItems} from "../../../../../../api/web3";
import Web3 from "web3";
import {createItem, updateDB, updateDesigners, updateOwners} from "../../../../../../api/auth";
import {createCollectionFunc, mint, sleep} from "../../../../../../utils";
import toast from "react-hot-toast";
import {images} from "../../../../../../assets/images/images";
import * as RoutesName from "../../../../../../Routes/routes";
import UploadFile from "../../../UploadFile/UploadFile";


const CreateCollection = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    const {auth, setAuth} = useAuth();

    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [upLoadFileData, setUploadFileData] = useState({
        type: "",
        name: "",
    });


    const exploreURL = window.env.REACT_APP_BLOCK_EXPLORER;


    const [uploading, setUploading] = useState(false);
    const [step, setStep] = useState("");


    const [itemData, setItemData] = useState({
        name: {value: "", error: []},
        symbol: {value: "", error: []},
        description: {value: "", error: []},
        externalLink: {value: "", error: []},
        avatarLink: {value: "", error: []},
        bannerLink: {value: "", error: []},
    });


    const createdContractAddress = useRef("")
    const transactionHash = useRef("")

    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="items.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...itemData,
            [e.target.dataset.name]: {...itemData[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setItemData(prevState)
    }

    const isFormValid = () => {
        let inputs = {...itemData}

        const hasError = Object.values(itemData).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            console.log(key)
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="items.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setItemData(inputs);
        return !isEmpty;
    }

    useEffect(()=>{
        /*if (auth?.imageIpfsLink) setItemData({...itemData,imageLink: { value: auth?.imageIpfsLink, error: []}})
        if (auth?.animationIpfsLink) setItemData({...itemData,mediaLink: { value: auth?.animationIpfsLink, error: []}})*/

        let newItemData = {...itemData}
        if (auth?.collection_avatarIpfsLink) newItemData.avatarLink = { value: auth?.collection_avatarIpfsLink, error: []}
        if (auth?.collection_bannerIpfsLink) newItemData.bannerLink = { value: auth?.collection_bannerIpfsLink, error: []}
        setItemData(newItemData)

    },[openUploadFile])



    const navigateToUploadPage = (type, name) => {
        setUploadFileData({
            type: type,
            name: name,
        })
        setOpenUploadFile(true)
        //navigate(Routes.UploadFile + "/" + type + "/" + name, {state: {from: location}})
    }

    const copyToClipboard = (value, e) => {
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success("Copied");
    }

    const backAfterCreate = async () => {
        setStep("")
        setUploading(false)

        setAuth({...auth,
            collection_avatarIpfsLink: "",
            collection_bannerIpfsLink: "",
        })
        setItemData({
            name: {value: "", error: []},
            symbol: {value: "", error: []},
            description: {value: "", error: []},
            externalLink: {value: "", error: []},
            avatarLink: {value: "", error: []},
            bannerLink: {value: "", error: []},
        })
    }

    const submit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) return false;

        if (!auth?.isConnected) return toast.error("Connect your wallet!")

        setUploading(true)


        const collectionData = {
            name: itemData.name.value,
            symbol: itemData.symbol.value,
            description: itemData.description.value,
            image: itemData.avatarLink.value,
            banner_image: itemData.bannerLink.value,
            external_link: itemData.externalLink.value,
        }


        await createItem(collectionData , auth.token)
            .then(async (res) => {

                setStep("CREATED")
                console.log("cid upload", res?.data?.data?.value?.cid)


                const createCollectionData = {
                    name: itemData.name.value,
                    symbol: itemData.symbol.value,
                    ipfsJson: `ipfs://${res?.data?.data?.value?.cid}`,
                    address: window.ethereum._state.accounts[0],
                }

                console.log("createCollectionData", createCollectionData)

                await createCollectionFunc(createCollectionData).then( async (res)=> {

                    console.log("res deploy", res)
                    //console.log("res deploy", res?.event["0"]?.address)
                    console.log("res deploy", res?.events)
                    console.log("res deploy", typeof res?.events[0])
                    console.log("res deploy", res?.events[0].address)
                    createdContractAddress.current = res?.events[0]?.address
                    transactionHash.current = res?.transactionHash

                    await sleep(2000)

                    await updateDB(auth.token).then( async ()=>{

                        await sleep(2000)

                        await updateOwners(auth.token).then( async ()=>{
                            setStep("DEPLOYED")
                        }).catch((e)=>{
                            console.log("error", e)
                        })

                    }).catch((e)=>{
                        console.log("error", e)
                    })



                }).catch((err)=>{
                    toast.error("Failed to Deploy, try again!");
                    setStep("")
                    setUploading(false)
                    console.log("err" , err)
                })




            })
            .catch((err) => {
                toast.error("Failed to upload file, try again!");
                setStep("")
                setUploading(false)
                console.log("err" , err)
            })
            .finally(() => {

            });


    };

    const wrapperContentHandeler = () => {

        if (step === "DEPLOYED") return <div className={`column jc-center ai-center`}>
            <div className={`row jc-around ai-center width-100`}>
                <div className={`row text-green mb-1`}>
                    <span className={`ml-05`}>Created</span>
                    <Icon
                        iconName="icon-ok-3 fs-04 "
                        iconClass={` mr-05`}
                    />
                </div>
                <div className={`row text-green mb-1`}>
                    <span className={`ml-05`}>Deployed</span>
                    <Icon
                        iconName="icon-ok-3 fs-04 "
                        iconClass={` mr-05`}
                    />
                </div>
            </div>
            <div className={`column jc-center ai-center width-100 my-2`}>
                <span className={`fs-02 font-weight-bold mb-05`}>Contract Address</span>

                <div className={`container row jc-center ai-center mt-05`}>
                    <Icon iconName="icon-copy-link-icon flex" iconClass={`ml-025 cursor-pointer hover`} onClick={(e) => copyToClipboard(createdContractAddress.current, e)} title={`Click to copy contract address`}/>
                    <span className={` mr-025`} >{createdContractAddress.current}</span>
                </div>
            </div>



            <div className={`width-100 row jc-center ai-center my-3`}>
                <div className={`width-33 column jc-center ai-center`}>
                    <img src={images.dorsaLogo} alt="boxes" className={`${classes.linkImage}`}/>
                    <span className={`my-2`}>Dorsa</span>
                    <Icon iconName="icon-popup-link-icon flex" iconClass={` cursor-pointer hover`} title={`${itemData?.name?.value} Collection in Dorsa`} onClick={()=>window.open(`${RoutesName.Collections}${createdContractAddress.current}/${itemData?.name?.value}`)}/>
                </div>
                <div className={`width-33 column  column jc-center ai-center ${classes.etherscan}`}>
                    <img src={images.etherscan} alt="etherscan" className={`${classes.linkImage}`}/>
                    <span className={`my-2`}>Etherscan</span>
                    <Icon iconName="icon-popup-link-icon flex" iconClass={` cursor-pointer hover`} title={`${itemData?.name?.value} Collection in Etherscan`} onClick={()=>window.open(`${exploreURL}/tx/${transactionHash.current}`)}/>
                </div>
            </div>

            <div className={`container row jc-center ai-center my-2`}>
                <Icon iconName="icon-copy-link-icon flex" iconClass={`ml-025 cursor-pointer hover`} onClick={(e) => copyToClipboard(transactionHash.current, e)} title={`Click to copy Transaction Hash`}/>
                <span className={`fs-0-8 mr-025`} >{transactionHash.current}</span>
            </div>

            <Button
                type="button"
                buttonClass={`${classes.backButton} cursor-pointer mt-2`}
                buttonTitle="Back"
                onClick={()=>backAfterCreate()}
            />




        </div>

        if (step === "CREATED") return <div className={`column jc-center ai-center`}>
            <div className={`row text-green mb-1`}>
                <span className={`ml-05`}>Created</span>
                <Icon
                    iconName="icon-ok-3 fs-04 "
                    iconClass={`cursor-pointer mr-05`}
                    onClick={()=>navigate(RoutesName.CreateItem)}
                />
            </div>
            <span className={`mt-1`}>Deploying...</span>
        </div>

        if (step === "") return <div>Creating...</div>

    }

    return ( <>
        <form onSubmit={(e)=>submit(e)} className={`width-80 m-auto py-5 min-height-100`}>
            <div className={``}>
                <Title title="Create Collection"/>
            </div>
            <div className={`width-100 mt-3 row jc-between wrap`}>
                <TextInput
                    label={t('createCollection.name')}
                    data-name="name"
                    data-type="input"
                    data-min={2}
                    type="text"
                    placeholder={t('createCollection.phName')}
                    inputClass={`width-30 my-1`}
                    value={itemData.name.value}
                    onchange={(e) => inputHandler(e)}
                    alerts={itemData.name.error}
                />
                <TextInput
                    label={t('createCollection.symbol')}
                    data-name="symbol"
                    data-type="input"
                    data-min={2}
                    type="text"
                    placeholder={t('createCollection.phSymbol')}
                    inputClass={`width-30 my-1`}
                    value={itemData.symbol.value}
                    onchange={(e) => inputHandler(e)}
                    alerts={itemData.symbol.error}
                />
                <TextInput
                    label={t('createCollection.externalLink')}
                    data-name="externalLink"
                    data-type="input"
                    data-min={2}
                    type="text"
                    placeholder={t('createCollection.phExternalLink')}
                    inputClass={`width-30 my-1`}
                    value={itemData.externalLink.value}
                    onchange={(e) => inputHandler(e)}
                    alerts={itemData.externalLink.error}
                />

                <TextInput
                    label={t('createCollection.description')}
                    data-name="description"
                    data-type="input"
                    data-min={5}
                    type="text"
                    textarea={true}
                    placeholder={t('createCollection.phDescription')}
                    //subLabel={t('createCollection.descriptionSublabel')}
                    inputClass={`width-100 my-3 ${classes.inputClass}`}
                    value={itemData.description.value}
                    onchange={(e) => inputHandler(e)}
                    alerts={itemData.description.error}
                />

                <TextInput
                    label={t('createCollection.avatarLink')}
                    data-name="avatarLink"
                    data-type="input"
                    data-min={10}
                    type="text"
                    placeholder={t('createCollection.phAvatarLink')}
                    subLabel={t('createCollection.avatarLinkSublabel')}
                    inputClass={`width-48 my-1`}
                    value={itemData.avatarLink.value}
                    onchange={(e) => inputHandler(e)}
                    alerts={itemData.avatarLink.error}
                    sideContent={<div className={`row jc-center ai-center mr-025 cursor-pointer hover-text`}
                                      onClick={() => navigateToUploadPage("image", "collection_avatar")}>
                        <Icon
                            iconName="icon-plus-circle fs-02"
                            iconClass={`cursor-pointer ml-025`}
                        />
                        <span>Upload File</span>
                    </div>}
                />
                <TextInput
                    label={t('createCollection.bannerLink')}
                    data-name="bannerLink"
                    data-type="input"
                    data-min={10}
                    type="text"
                    placeholder={t('createCollection.phBannerLink')}
                    subLabel={t('createCollection.bannerLinkSublabel')}
                    inputClass={`width-48 my-1`}
                    value={itemData.bannerLink.value}
                    onchange={(e) => inputHandler(e)}
                    alerts={itemData.bannerLink.error}
                    sideContent={<div className={`row jc-center ai-center mr-025 cursor-pointer hover-text`}
                                      onClick={() => navigateToUploadPage("image", "collection_banner")}>
                        <Icon
                            iconName="icon-plus-circle fs-02"
                            iconClass={`cursor-pointer ml-025`}
                        />
                        <span>Upload File</span>
                    </div>}
                />
            </div>
            <div className={`width-100 mt-5`}>
                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} cursor-pointer`}
                    buttonTitle={t('items.create')}
                />
            </div>
        </form>
        { uploading && <div className={`${classes.wrapper} position-absolute column jc-center ai-center`}>
                <span
                    className={`font-weight-bold fs-20 text-white mb-3`}>{ step === "DEPLOYED" ?`${itemData.name.value} Collection created successfully` :
                    "Create Collection ..."}</span>

                <div
                    className={`${classes.wrapperContent} mt-3 width-38 rounded-8 column jc-center ai-center py-3 px-2`}>
                    {step !== "DEPLOYED" &&
                        <img src={images.dorsaLoading} className={`${classes.loading} mb-2`} alt="loading..."/>}
                    <span className={`fs-01`}>{wrapperContentHandeler()}</span>
                </div>

            </div>}
        { openUploadFile && <UploadFile type={upLoadFileData?.type} name={upLoadFileData?.name} setOpenUploadFile={setOpenUploadFile}/>}
        </>
    );
};

export default CreateCollection;
