import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import "./assets/fontIcon/css/dorsa-icon.css";
import "./i18n/i18n";
import {AuthProvider} from "./context/AuthProvider";
import {BrowserRouter, Route, Routes, ScrollRestoration} from "react-router-dom";
import Main from "./main/Main";

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
      <AuthProvider>
          <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                  <Routes>
                      <Route path="/*" element={<Main/>}/>
                  </Routes>

              </BrowserRouter>
              <ReactQueryDevtools initialIsOpen={false}/>
          </QueryClientProvider>
      </AuthProvider>
  </React.StrictMode>
);

